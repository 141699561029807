import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useFormik } from "formik";
import axios from "axios";
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';

export default function Add({type, editId, setEditId, show, setShow, loading, setLoading, getList, brandId, setBrandId, showParameters, setShowParameters, ...rest}){
    
const validationSchema = yup.object().shape({
    brandName: yup.string('Enter your Brand Name').required('Brand Name is required'),
    adminName: yup.string('Enter Admin Name').required('Admin Name is required'),
    // email: yup.string()
    //     .required('Email is required')
    //     .test('is-valid-email', 'Enter a valid email address', value => {
    //         // Basic custom email validation logic
    //         return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    // }),
    email: yup.string()
    .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        'Enter a valid email address'
    )
    .required('Email is required')
    .test(
        'is-email-unique',
        'Email already exists!',
        async (value) => await handleCheckIsEmailAlreadyExist(value)
    ),
    phone: yup.string()
        .nullable()
        .notRequired()
        .min(10, "Must be more than 10 characters")
        .max(20, "Must be less than 20 characters"),
});
    const initialValues = {
        id: editId,
        brandId: '',
        brandName: '',
        adminName: '',
        email: '',
        phone: '',
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    useEffect(() => {
        if(editId > 0){
            axios.get(`api/brand?id=${editId}`).then(res => {
                if(res.data.success){
                    formik.setValues({
                        id: res.data.data.id,
                        brandId: res.data.data.brandId,
                        brandName: res.data.data.brand.name,
                        adminName: res.data.data.firstName,
                        email: res.data.data.email,
                        phone: res.data.data.phone,
                    });
                }
            });
        }
        else{
            formik.resetForm();
        }
    },[show]);

    const handleClose = () => setShow(false);
    
    const handleCheckIsEmailAlreadyExist = async (email) => {
        try {
            const response = await axios.post("/api/auth/checkIsEmailAlreadyExist", { id: formik.values.id, email });
            return !response.data.exists;
        } catch (error) {
            console.error("Error checking email existence:", error);
            return true;
        }
    };

    const handleCloseModal = () => {
        formik.resetForm(); 
        handleClose(); 
    };

    const handleSubmit = async (values) => {
        setLoading(true);        
        try {
            const apiCall = editId > 0 ? axios.put : axios.post;
            const apiUrl = editId > 0 ? `api/brands/update` : `api/brands/create`;
    
            const response = await apiCall(apiUrl, values);    
            setLoading(false);
            setShow(false);
            const { success, message, data } = response.data;
            if (success) {
                setBrandId(data.user.brandId);
                await Swal.fire(message, '', 'success');    
                getList();
                formik.resetForm();
                
                if (editId <= 0) {
                    setShowParameters(true);
                }
            } else {                
                Swal.fire(message, '', 'error');
            }
        } catch (error) {
            setLoading(false);
            setShow(false);    
            console.error("Error during API call:", error);
            const errorMessage = error.response?.data?.message || error.message || "An unexpected error occurred";    
            Swal.fire(errorMessage, '', 'error');
        }
    };

    return (
        <Modal show={show} onHide={handleCloseModal}>
            <form autoComplete="off" onSubmit={formik.handleSubmit} encType="multipart/form-data">
                <Modal.Header>
                    <h1 className="modal-title" id="addUploadLabel">{type} Brand</h1>
                    <Link className="ss-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
                        <i className="fa-solid fa-xmark"></i>
                    </Link>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3 flex-column">
                                <label htmlFor="brandName" className="form-label">Brand Name</label>
                                <input type="text" id="brandName" className="form-control" name="brandName"
                                    value={formik.values.brandName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.brandName && formik.errors.brandName && (
                                    <div className="text-danger errorMSG">{formik.errors.brandName}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3 flex-column">
                                <label htmlFor="adminName" className="form-label">Admin Name</label>
                                <input type="text" id="adminName" className="form-control" name="adminName"
                                    value={formik.values.adminName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.adminName && formik.errors.adminName && (
                                    <div className="text-danger errorMSG">{formik.errors.adminName}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3 flex-column">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input type="text" id="email" className="form-control" name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className="text-danger errorMSG">{formik.errors.email}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3 flex-column">
                                <label htmlFor="phone" className="form-label">Contact No.</label>
                                <input type="text" id="phone" className="form-control" name="phone"
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.phone && formik.errors.phone && (
                                    <div className="text-danger errorMSG">{formik.errors.phone}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="add-btn" onClick={handleCloseModal}>
                        Close
                    </button>
                    <button type="submit" className="save-btn" disabled={loading}>
                        {loading ? 'Processing...' : 'Save'}
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}