import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export default function BrandMetricsChart({ chartdata }) {

    const isSelfDataValid = chartdata && chartdata.selfReportData && chartdata.selfReportData.length > 0;
    const isCompetitorDataValid = chartdata && chartdata.competitorReportData && chartdata.competitorReportData.length > 0;

    if (!isSelfDataValid && !isCompetitorDataValid) {
        return <div className="App" style={{ height: 'auto', width: "100%", color: 'white' }}>
            <p className="text-black ms-4">No data available!</p>
        </div>;
    }

    const formatValue = (value) => {
        if (value && (typeof value === 'number' || typeof value === 'string') && !isNaN(value)) {
            return parseFloat(Number(value).toFixed());
        }
        return 0;
    };

    const desiredLabelsOrder = ["Unprompted Awareness", "Prompted Awareness", "Consideration", "Investigation", "Main bank for borrowing", "Preference", "Preferred alternative"];
    // const desiredLabelsQuestions  = ["Unprompted Awareness", "Prompted Awareness", "Consideration", "Investigation", "Main bank for borrowing", "Preference", "Preferred alternative"];

    const desiredLabelsQuestions = {
        "Unprompted Awareness": "When thinking about businesses that provide banking and financial services, which providers or brands first come to mind/are you aware of?",
        "Prompted Awareness": "From the list below, please select all the banking and financial services providers or brands you have heard of before today, even if you listed them earlier:",
        "Consideration": "Which of the following banking or financial service providers would you consider using in the future?",
        "Investigation": "Before today, which of the following banking or financial service providers have you looked into or researched?",
        "Main bank for borrowing": "And which banking and financial service provider do you use currently for the majority of your agribusiness borrowing?",
        "Preference": "Which of the following is your preferred bank?",
        "Preferred alternative": "And if you had to change providers for your agribusiness borrowing, which provider is the most appealing to you?",
    };

    const splitTextIntoChunks = (text, chunkSize) => {
        const words = text ? text.split(' ') : '';
        const chunks = [];

        for (let i = 0; i < words.length; i += chunkSize) {
            chunks.push(words.slice(i, i + chunkSize).join(' '));
        }

        return chunks;
    };

    const labels = chartdata && chartdata.selfReportData
        ? chartdata.selfReportData.map(item => item.type).sort((a, b) => desiredLabelsOrder.indexOf(a) - desiredLabelsOrder.indexOf(b))
        : [];

    const getDataForLabels = (dataArray, labels) => {
        return labels.map(label => {
            const foundItem = dataArray.find(item => item.type === label);
            return foundItem ? formatValue(foundItem.value) : 0;
        });
    };

    const separatorLinePlugin = {
        id: 'separatorLine',
        beforeDraw: (chart) => {
            const { ctx, scales } = chart;
            const meta0 = chart.getDatasetMeta(0); // First bar (Dataset 1 - Orange bars)
            const meta1 = chart.getDatasetMeta(1); // Second bar (Dataset 2 - Grey bars)

            ctx.save();
            ctx.setLineDash([1, 1.5]); // Dotted line configuration
            ctx.strokeStyle = 'grey';
            ctx.lineWidth = 1.5;

            for (let i = 0; i < meta0.data.length; i++) {
                const barHeight = meta0.data[i].height;

                // Get the Y coordinates of the two bars for each label
                const y0 = meta0.data[i].y; // Y coordinate of the first bar
                const y1 = meta1.data[i].y; // Y coordinate of the second bar
                const separatorY = (y0 + y1) / 2; // Midpoint between the two bars

                // Draw the separator line for each pair of bars
                ctx.beginPath();
                ctx.moveTo(scales.x.left, separatorY);
                ctx.lineTo(scales.x.right, separatorY);
                ctx.stroke();
            }

            ctx.restore();
        }
    };

    const data = {
        labels: labels,
        datasets: [
            {
                label: isSelfDataValid ? chartdata.selfReportData[0].brand_name : 'Dataset 1',
                data: isSelfDataValid ? getDataForLabels(chartdata.selfReportData, labels) : labels.map(() => 0),
                backgroundColor: '#f06b22',
                borderColor: '#f06b22',
                borderWidth: 1,
                borderRadius: 10, //[10, 10, 10, 10, 10, 10],
                barPercentage: 0.7,
                categoryPercentage: 1, //0.99,
                borderSkipped: false,
                datalabels: {
                    // color: '#fff',
                    color: (context) => {
                        const value = context.dataset.data[context.dataIndex];
                        return value === 0 ? '#000' : '#fff'; 
                    },
                    font: {
                        size: window.innerWidth < 767 ? 10 : 14,
                        // weight: 'bold'
                        family: 'VisbyCF-Bold, sans-serif',
                        letterSpacing: '1.5px',
                    },
                },
            },
            {
                label: isCompetitorDataValid ? chartdata.competitorReportData[0].comp_name : 'Dataset 2',
                data: isCompetitorDataValid ? getDataForLabels(chartdata.competitorReportData, labels) : labels.map(() => 0),
                backgroundColor: '#dae8d8', // Light blue
                borderColor: '#dae8d8',
                borderWidth: 1,
                borderRadius: 10, //[10, 10, 10, 10, 10, 10],
                borderSkipped: false,
                barPercentage: 0.7,
                categoryPercentage: 0.99,
                datalabels: {
                    color: '#000',
                    font: {
                        size: window.innerWidth < 767 ? 8 : 10,
                        family: 'VisbyCF-medium, sans-serif',
                        letterSpacing: '1.5px',
                    },
                }
            }
        ]
    };

    const options = {
        indexAxis: 'y', // Horizontal bar chart
        responsive: true,
        maintainAspectRatio: window.innerWidth < 767 ? false : true,
        scales: {
            x: {
                beginAtZero: true,
                grid: {
                    color: '#424242',
                    display: false // Hide grid lines
                },
                ticks: {
                    display: false,
                    color: '#ffffff', // X-axis text color
                    font: {
                        family: 'VisbyCF-Medium, sans-serif',
                        letterSpacing: 1.5,
                    }
                },
                border: {
                    display: false,
                },
            },
            y: {
                grid: {
                    color: '#424242',
                    display: false, // Hide grid lines
                },
                ticks: {
                    color: '#000', // Y-axis text color
                    font: (context) => {
                        const width = context.chart.width;
                        return {
                            size: width < 767 ? 10 : 12, // Responsive font size for Y-axis
                            family: 'VisbyCF-Medium, sans-serif',
                            letterSpacing: 1.5,
                        };
                    },
                    align: window.innerWidth < 767 ? 'start' : 'start', // Align left for mobile, center for desktop
                    padding: window.innerWidth < 767 ? 10 : 10,
                    crossAlign: 'far', //align the labels to left 
                    // callback: function (value) {
                    //     const label = this.getLabelForValue(value);
                    
                    //     // Insert spaces between characters for Y-axis labels only
                    //     const spacedLabel = label.split('').join('\u2009'); // Apply letter spacing by adding a space between characters
                    
                    //     const words = spacedLabel.split(' ');
                    //     let lines = [];
                    //     let currentLine = '';
                    
                    //     // Set a character limit for each line
                    //     const maxLineLength = 50; // Adjust based on your design preference
                    
                    //     for (let i = 0; i < words.length; i++) {
                    //         const word = words[i];
                    
                    //         // Check if adding the next word exceeds the max line length
                    //         if (currentLine.length + word.length + 1 > maxLineLength) {
                    //             // Only push the current line if it's not empty
                    //             if (currentLine.length > 0) {
                    //                 lines.push(currentLine); // Push current line to lines array
                    //                 currentLine = ''; // Reset current line
                    //             }
                    
                    //             // Check if the word ends with a space and is longer than the max length
                    //             if (word.length > maxLineLength && word.endsWith(' ')) {
                    //                 // Split long words into smaller chunks
                    //                 let subWords = word.match(new RegExp('.{1,' + maxLineLength + '}', 'g')) || [];
                    //                 for (let subWord of subWords) {
                    //                     lines.push(subWord.trim()); // Push each chunk as a new line, removing any trailing space
                    //                 }
                    //             } else {
                    //                 // If the current line is empty, we can safely assign the word to it
                    //                 currentLine = word; // Start a new line with the current word
                    //             }
                    //         } else {
                    //             // If the word fits, add it to the current line
                    //             currentLine += (currentLine.length > 0 ? ' ' : '') + word; // Add space before word if not empty
                    //         }
                    //     }
                    
                    //     // Push the last line if there are any remaining words
                    //     if (currentLine.length > 0) {
                    //         lines.push(currentLine);
                    //     }
                    
                    //     // Handle responsive behavior for smaller screens
                    //     if (window.innerWidth < 767) {
                    //         return lines.slice(0, 2); // Show only the first two lines on smaller screens
                    //     }
                    
                    //     return lines; // Return the multiline labels
                    // },
                    
                },
                border: {
                    display: false,
                },
                categoryPercentage: 0.6,
                barPercentage: 0.8,
            }
        },
        plugins: {
            legend: {
                display: false,
                labels: {
                    color: '#ffffff',
                    font: {
                        family: 'VisbyCF-Medium, sans-serif',
                        letterSpacing: 1.5,
                    }
                }
            },
            datalabels: {
                font: (context) => {
                    const width = context.chart.width;
                    return {
                        size: width < 767 ? 10 : 12, // Responsive font size
                        family: 'VisbyCF-Medium, sans-serif',
                        letterSpacing: 1.5,
                    };
                },
                color: '#ffffff',
                formatter: (value) => value.toFixed() + '%',
                // align: 'start',
                // // anchor: 'end',
                align: (context) => {
                    const value = context.dataset.data[context.dataIndex];
                    return (value <= 1 && value >= 0) ? 'end' : 'start';
                    // return (value <= 2 && value >= 0) ? 'end' : 'start';
                    // return (value >= 0 && value <= 5) ? 'end' : 'start';
                },
                anchor: (context) => {
                    const value = context.dataset.data[context.dataIndex];
                    return (value <= 1 && value >= 0) ? 'end' : 'end';
                    // return (value <= 2 && value >= 0) ? 'end' : 'end';
                    // return (value >= 0 && value <= 3) ? 'outside' : 'end';
                },
            },
            tooltip: {
                // callbacks: {
                //         title: function (tooltipItem) {
                //             return `${tooltipItem[0].label} : ${desiredLabelsQuestions[tooltipItem[0].label]}`;
                //         },                  
                //         label: function (tooltipItem) {                        
                //         return `${tooltipItem.dataset.label}: ${tooltipItem.raw}%`;
                //     }
                // }
                callbacks: {
                    title: function (tooltipItem) {

                        const additionalInfo = desiredLabelsQuestions[tooltipItem[0].label];
                        const textChunks = splitTextIntoChunks(additionalInfo, 10);
                        // return `${tooltipItem[0].label} : \n ${textChunks}`;
                        return [tooltipItem[0].label, ...textChunks];
                    },
                    label: function (tooltipItem) {
                        const label = tooltipItem.dataset.label;
                        return [tooltipItem.dataset.label + ': ' + tooltipItem.raw] + '%';
                    }
                },
                titleFont: {
                    family: 'VisbyCF-medium, sans-serif',
                    letterSpacing: 1.5,
                    size: 12,
                },
                bodyFont: {
                    family: 'VisbyCF-medium, sans-serif',
                    letterSpacing: 1.5,
                    size: 11
                },
                footerFont: {
                    family: 'VisbyCF-medium, sans-serif',
                    letterSpacing: 1.5,
                    size: 10
                }
            }
        },
        layout: {
            padding: {
                // top: 12, //'5%',
                top: 20, //'5%',
                right: 20, //'5%',
                bottom: 20, //'5%',
                // left: 20, //'5%'
                left: 12,
            }
        }
    };

    return (
        <div className="App metricChart" style={{ height: 'auto', width: "100%" }}>
            <Bar data={data} options={options} plugins={[separatorLinePlugin]} />
        </div>
    );
}