import * as yup from 'yup';
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import Swal from "sweetalert2";
import axios from "axios";

const validationSchema = yup.object({
    email: yup.string()
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            'Enter a valid email address'
        )
        .required('Email is required'),
});

export default function ForgotPassword(){
    const navigate = useNavigate();
    const initialValues = {
        email: ''
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);            
        }
    });

    const handleSubmit = (values) => {
        axios.post('/api/users/forgotpassword', values).then((res) =>{
            if(res.data.success){
                Swal.fire(res.data.message, '', 'success').then(result => {
                    navigate("/login")                 
                })                
            }
            else{
                Swal.fire(res.data.message, '', 'info')
            }
        }).catch((error) => {
            Swal.fire(error.message, '', 'info')        
        });
    }
    return(
        <div className="bg-img">
            <section className="d-flex align-items-center justify-content-center flex-column min-vh-100 py-4">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-5 col-md-7">
                            <div className="card border-0 card_shadow">
                                <div className="card-body">
                                    <div className="login-panel pb-0">
                                        <h1><span>Ag</span>Track</h1>
                                        <p>Don't worry, it happens! Enter your email to reset your password and get back on
                                            track.</p>
                                        <form className="mb-3" onSubmit={formik.handleSubmit} method="POST">
                                            <div className="form-floating mb-3">
                                                <input 
                                                    type="email" 
                                                    className="form-control" 
                                                    id="email"
                                                    placeholder="Enter your email" 
                                                    autocomplete="off" 
                                                    value={formik.values.email} 
                                                    onChange={formik.handleChange} 
                                                    onBlur={formik.handleBlur}
                                                    />
                                                <label htmlFor="email">Email address</label>
                                                {formik.touched.email && formik.errors.email && (
                                                    <div className="text-danger errorMSG">{formik.errors.email}</div>
                                                )}
                                            </div>
                                            <div className="d-grid">
                                                <button className="login-btn w-100">Reset Password</button>
                                            </div>
                                        </form>
                                        <p className="fw-semibold"><i className="bi bi-arrow-left me-2"></i>Back to <Link to="/login" className=""><span>Login</span></Link></p>
                                        <hr />
                                        <p className="text-sm ssBold">
                                            © 2024 <span className="fw-semibold" style={{color:'#F06B22'}}>Ag</span><span
                                                className="fw-semibold">Track</span> - All Rights Reserved.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}