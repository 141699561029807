import React, { useEffect } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend, layouts } from 'chart.js';
import { Line } from "react-chartjs-2";
ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

export default function TimelineChart({ chartData, selectedTimelineOption }) {

    const isSelfDataValid = chartData && chartData.selfReportData && chartData.selfReportData.length > 0;
    const isCompetitorDataValid = chartData && chartData.competitorReportData && chartData.competitorReportData.length > 0;

    if (!isSelfDataValid) {
        return <div className="App" style={{ height: 'auto', width: "100%", color: '#000' }}>
            No data available!
        </div>;
    }

    // Filter the labels to only show from September 2024 and exclude July and August
    // const labels = chartData && chartData.selfReportData
    //     ? chartData.selfReportData
    //           .filter(item => item.year === 2024 && ['September', 'October', 'November', 'December'].includes(item.month))
    //           .map(item => item.month)
    //     : [];
    const monthAbbreviations = ['January','February','March','April','May','June','July','August','September','October','November','December']//["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const labels = chartData && chartData.selfReportData
        ? chartData.selfReportData
            //.filter(item => item.year === 2024 && ['September', 'October', 'November', 'December'].includes(item.month))
            .map(item => `${monthAbbreviations[item.month - 1]} ${item.year.toString().slice(-2)}`) 
        : [];

    const formatValue = (value) => {
        if (typeof value === 'number' && !isNaN(value)) {
            return parseFloat(value);
        }
        return 0; // Fallback to 0 if value is undefined or not a number
    };

    const getDataForLabels = (dataArray, labels) => {
        return labels.map(label => {
            const [month, year] = label.split(' ');

            const foundItem = dataArray.find(item =>
                monthAbbreviations[item.month - 1] === month && item.year.toString().slice(-2) === year
            );
// console.log("foundItemfoundItemfoundItem",foundItem)
            if (selectedTimelineOption === 'NPS') {
                return foundItem ? parseFloat(Number(foundItem.nps).toFixed()) : 0;
            } else if (selectedTimelineOption === 'Promoters') {
                return foundItem ? parseInt(Number(foundItem.promoters).toFixed()) : 0;
            } else if (selectedTimelineOption === 'Passives') {
                return foundItem ? parseInt(Number(foundItem.passive).toFixed()) : 0;
            } else if (selectedTimelineOption === 'Detractors') {
                return foundItem ? parseInt(Number(foundItem.detractors).toFixed()) : 0;
            } else {
                return foundItem ? parseFloat(Number(foundItem.value).toFixed()) : 0;
            }
        });
    };

    // Check if there is any valid data after filtering
    const selfData = isSelfDataValid ? getDataForLabels(chartData.selfReportData, labels) : [];
    const competitorData = isCompetitorDataValid ? getDataForLabels(chartData.competitorReportData, labels) : [];

    const hasValidData = selfData.some(val => val !== 0) || competitorData.some(val => val !== 0);

    if (!hasValidData) {
        return <div className="App" style={{ height: 'auto', width: "100%", color: 'white' }}>No data available!</div>;
    }

    const data = {
        labels: labels,
        datasets: [
            {
                label: isSelfDataValid ? chartData.selfReportData[0].brand_name : 'Dataset 1',
                data: selfData,
                borderColor: '#f06b22',
                backgroundColor: '#f06b22',
                fill: true,
                pointRadius: window.innerWidth < 767 ? 13 : 18, // Responsive circle size
                pointHoverRadius: window.innerWidth < 767 ? 13 : 18,
                tension: 0.4,
                datalabels: {
                    align: 'center',
                    anchor: 'center',
                    color: '#fff',
                    font: {
                        size: window.innerWidth < 767 ? 10 : 14, // Adjust font size
                        weight: 'bold', // Set font weight
                        family: 'VisbyCF-Medium, sans-serif',
                        letterSpacing: 1.5,
                    },
                    formatter: function (value) {
                        return value + '%';
                    },
                },
                pointBackgroundColor: '#f06b22',
                type: 'line',
                borderRadius: 4,
                pointStyle: 'rectRounded',
                padding: 6,
                borderWidth: 3,
            },
            {
                // label: isCompetitorDataValid ? chartData.competitorReportData[0].comp_name : 'Dataset 2',
                label: isCompetitorDataValid ? chartData.competitorReportData[0].comp_name || chartData.competitorReportData[0].main_bank : 'Dataset 2',

                data: competitorData,
                borderColor: '#dae8d8',
                backgroundColor: '#dae8d8',
                fill: true,
                tension: 0.4,
                datalabels: {
                    align: 'center',
                    anchor: 'center',
                    color: '#000',
                    font: {
                        size: window.innerWidth < 767 ? 8 : 10, // Adjust font size
                        family: 'VisbyCF-Medium, sans-serif',
                        letterSpacing: 1.5,
                    },
                    formatter: function (value) {
                        return value + '%';
                    },
                },
                pointRadius: window.innerWidth < 767 ? 13 : 16, // Responsive circle size
                pointHoverRadius: window.innerWidth < 767 ? 13 : 16,
                pointBackgroundColor: '#dae8d8',
                type: 'line',
                borderRadius: 4,
                pointStyle: 'rectRounded',
                padding: 9,
                borderWidth: 3,
            }
        ]
    };

    const chartAreaBorderPlugin = {
        id: 'chartAreaBorder',
        beforeDraw(chart) {
            const { ctx, chartArea: { left, top, right, bottom } } = chart;
            ctx.save();
            ctx.strokeStyle = 'grey';
            ctx.lineWidth = 1.5;
            ctx.setLineDash([1, 1.5]);

            ctx.beginPath();
            ctx.moveTo(left, bottom);
            ctx.lineTo(right, bottom);
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(left, top);
            ctx.lineTo(left, bottom);
            ctx.stroke();

            ctx.restore();
        }
    };

    const options = {
        responsive: true,
        maintainAspectRatio: window.innerWidth < 767 ? false : true,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                color: 'white',
                callbacks: {
                    label: function (tooltipItem) {
                        return tooltipItem.dataset.label + ': ' + tooltipItem.raw;
                    }
                },
                titleFont: {
                    family: 'VisbyCF-medium, sans-serif',
                    letterSpacing: 1.5,
                    size: 12,
                },
                bodyFont: {
                    family: 'VisbyCF-medium, sans-serif',
                    letterSpacing: 1.5,
                    size: 11
                },
                footerFont: {
                    family: 'VisbyCF-medium, sans-serif',
                    letterSpacing: 1.5,
                    size: 10
                }
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#92938e',
                    font: (context) => {
                        const width = context.chart.width;
                        return { 
                            size: width < 767 ? 10 : 12,
                            family: 'VisbyCF-Medium, sans-serif',
                            letterSpacing: 1.5,  
                        };
                    },
                    padding: 5,
                },
                offset: true,
                beginAtZero: false,
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#92938e',
                    font: {
                        family: 'VisbyCF-Medium, sans-serif',
                    },
                    padding: 5,
                    callback: function (value) {
                        return value;
                    },
                    stepSize: 25,
                    align: 'center',  // Aligns the labels to start inside the chart
                    crossAlign: 'far'
                },
                //  position: 'left', 
                offset: true,
                beginAtZero: true,
                // min: 0, 
            }
        },
    };


    return (
        <div className="App" style={{ height: 'auto', width: "100%" }}>
            <Line data={data} options={options} plugins={[chartAreaBorderPlugin]} />
        </div>
    );
}