import { useEffect, useRef, useState } from "react";

export default function MonthYearFilters({
    // brandId, 
    handleMonthAndYearChange, 
    // monthYearOptions, setMonthYearOptions, selectedMonthYear
    month,
    setMonth
}) {
    
    // useEffect(() => {
    //     getMonthAndDate()
    // }, [])
    
    // const getMonthAndDate = async () => {
    //     try {
    //         const response = await axios.get(`/api/getMonthAndYearsByBrandId?brandId=${brandId}`);
    //         let result = response.data;
    //         if (result.success) {
    //             // console.log("Months and Date List:",result.data)
    //             const filteredMonthYearOptions = result.data.filter(item => {
    //                 return !(item.month === 'July' && item.year === 2024) && !(item.month === 'August' && item.year === 2024);
    //             });
    //             setMonthYearOptions(filteredMonthYearOptions);
    //         }
    //     } catch (err) {
    //         console.log(err);
    //         Swal.fire("Error in getting Months and Date List: " + err);
    //     }
    // }
    const [selectedMonthValue, setSelectedMonthValue] = useState('1');
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const SelectMonthOptions = [
        { label: "Latest month", value: "1" },
        { label: "3 months", value: "3" },
        { label: "6 months", value: "6" },
        { label: "12 months", value: "12" },
        { label: "2 years", value: "24" },
        { label: "3 years", value: "36" },
    ];

    const handleSelectMonthChange = (value) => {
        // const value = event.target.value;
        // console.log('value===========',value)
        setSelectedMonthValue(value);
        // handleMonthAndYearChange(value);
        setMonth(value);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false); // Close dropdown
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return(
        <div className={`custom-dropdown ${isOpen ? 'dropdown-border' : ''}`}ref={dropdownRef}>
            <div className="">
                {/* <Autocomplete
                    id="month-year-select"
                    className="autoComplete-select reportsAutocompleteInput"
                    size="small"
                    style={{ backgroundColor: 'transparent', border: '0' }}
                    options={monthYearOptions}
                    getOptionLabel={(option) => option.month_year}
                    value={selectedMonthYear || null}
                    onChange={handleMonthAndYearChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Month and Year"
                            className="form-control autoComplete-select"
                            placeholder="Select Month and Year"
                        />
                    )}
                    isOptionEqualToValue={(option, value) => option.month_year === value.month_year}
                /> */}

                {/* <select
                    className="form-select filterSelect"
                    value={selectedMonthValue}
                    onChange={handleSelectMonthChange}
                >
                    {SelectMonthOptions.map((option) => (
                        <option 
                        key={option.value} 
                        className="select-hover-background"
                        value={option.value} 
                        style={{ fontFamily: selectedMonthValue === option.value ? 'VisbyCF-Bold' : 'VisbyCF-Medium',
                            backgroundColor: selectedMonthValue === option.value ? 'white': 'white'
                        }} // Style for options
                    >
                            {option.label}
                        </option>
                    ))}
                </select> */}
                <div className={`dropdown-header ${isOpen ? '' : 'border-bottom-left-right-radius'}`} onClick={() => setIsOpen(!isOpen)}>
                    {SelectMonthOptions.find(option => option.value === selectedMonthValue)?.label || "Select an option"}
                    <span className={`arrow ${isOpen ? 'open' : ''}`}>&#9662;</span>
                </div>

                {isOpen && (
                    <ul className="dropdown-list">
                        {SelectMonthOptions.map((option) => (
                            <li
                                key={option.value}
                                className={`dropdown-item-select ${selectedMonthValue === option.value ? 'selected' : ''}`}
                                onClick={() => handleSelectMonthChange(option.value)}
                            >
                                {option.label}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    )
}