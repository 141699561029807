import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useFormik } from "formik";
import axios from "axios";
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';


export default function Add({type, editId, setEditId, show, setShow, loading, setLoading, getList, ...rest}){ 
    const authUser = localStorage.getItem('ag-authUser');
    const user = JSON.parse(authUser);
    const brandId = user.brandId;
    const initialValues = {
        id: editId,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        designation: '',
    };

    const validationSchema = yup.object().shape({
        firstName: yup.string('Enter your First Name').required('First Name is required'),
        lastName: yup.string('Enter your Last Name').required('Last Name is required'),
        email: yup.string()
            .matches(
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                'Enter a valid email address'
            )
        .required('Email is required')
        .test(
            'is-email-unique',
            'Email already exists!',
            async (value) => await handleCheckIsEmailAlreadyExist(value)
        ),
        phone: yup.string()
            .nullable()
            .notRequired()
            .min(10, "Must be more than 10 characters")
            .max(20, "Must be less than 20 characters"),
        designation: yup.string('Enter your Designation').required('Designation is required'),
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    const handleCheckIsEmailAlreadyExist = async (email) => {
        try {
            const response = await axios.post("/api/auth/checkIsEmailAlreadyExist", { id: formik.values.id, email });
            return !response.data.exists;
        } catch (error) {
            console.error("Error checking email existence:", error);
            return true;
        }
    };

    useEffect(() => {
        if(editId > 0){
            axios.get(`api/user?id=${editId}`).then(res => {
                if(res.data.success){
                    formik.setValues({
                        id: res.data.data.id,
                        firstName: res.data.data.firstName,
                        lastName: res.data.data.lastName,
                        email: res.data.data.email,
                        phone: res.data.data.phone,
                        designation: res.data.data.designation,
                    });
                }
            });
        }
        else{
            formik.resetForm();
        }
    },[show]);

    const handleClose = () => setShow(false);

    const handleSubmit = (values) => {
        setLoading(true);
        const apiCall = editId > 0 ? axios.put : axios.post;
        const apiUrl = editId > 0 
        ? `api/users/update?brandId=${brandId}` 
        : `api/users/create?brandId=${brandId}`;
        apiCall(apiUrl, values).then(res => {
            setLoading(false);
            setShow(false);
            if (res.data.success) {
                Swal.fire(res.data.message, '', 'success').then(() => {
                    getList();
                    formik.resetForm();
                });
            } else {
                Swal.fire(res.data.message, '', 'error');
            }
        }).catch((error) => {
            setLoading(false);
            setShow(false);
            Swal.fire(error.response.data.message, '', 'error');
        });
    };

    const handleCloseModal = () => {
        formik.resetForm(); 
        handleClose(); 
    };
    
    return (
        <Modal show={show} onHide={handleCloseModal}>
            <form autoComplete="off" onSubmit={formik.handleSubmit} encType="multipart/form-data">
                <Modal.Header>
                    <h1 className="modal-title" id="addUploadLabel">{type} User</h1>
                    <Link className="ss-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
                        <i className="fa-solid fa-xmark"></i>
                    </Link>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3 flex-column">
                                <label htmlFor="firstName" className="form-label">First Name</label>
                                <input type="text" id="firstName" className="form-control" name="firstName"
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.firstName && formik.errors.firstName && (
                                    <div className="text-danger errorMSG">{formik.errors.firstName}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3 flex-column">
                                <label htmlFor="lastName" className="form-label">Last Name</label>
                                <input type="text" id="lastName" className="form-control" name="lastName"
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.lastName && formik.errors.lastName && (
                                    <div className="text-danger errorMSG">{formik.errors.lastName}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3 flex-column">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input type="text" id="email" className="form-control" name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className="text-danger errorMSG">{formik.errors.email}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3 flex-column">
                                <label htmlFor="phone" className="form-label">Contact No.</label>
                                <input type="text" id="phone" className="form-control" name="phone"
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.phone && formik.errors.phone && (
                                    <div className="text-danger errorMSG">{formik.errors.phone}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3 flex-column">
                                <label htmlFor="designation" className="form-label">Designation</label>
                                <input type="text" id="designation" className="form-control" name="designation"
                                    value={formik.values.designation}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.designation && formik.errors.designation && (
                                    <div className="text-danger errorMSG">{formik.errors.designation}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="add-btn" onClick={handleCloseModal}>
                        Close
                    </button>
                    <button type="submit" className="save-btn" disabled={loading}>
                        {loading ? 'Processing...' : 'Save'}
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}