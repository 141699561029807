export const Loader = ({loading, title}) => {
    return (
        loading ?
            <div className="loader-container">
                <h5>{title}</h5><div className="spinner"></div>
            </div>
        :""
    )
}
export default Loader;
