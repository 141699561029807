import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, Title, Tooltip, Legend, ScatterController } from 'chart.js';
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, Title, Tooltip, Legend, ScatterController, ChartDataLabels);

export default function BrandAttributesChart({ selfData, competitorData }) {

    const isSelfDataValid = selfData && selfData.length > 0;
    const isCompetitorDataValid = competitorData && competitorData.length > 0;
    if (!isSelfDataValid || !isCompetitorDataValid) {
        return <div className="App" style={{ height: 'auto', width: "100%", color: 'white' }}>
            <p className="text-black ms-4">No data available!</p>
        </div>;
    }

    const formatValue = (value) => {
        if (typeof value === 'number' && !isNaN(value)) {
            return parseFloat(value.toFixed());
        }
        return 0; // Fallback to 0 if value is undefined or not a number
    };

    const data = {
        labels: selfData ? selfData.filter(item => item.parameter !== 'None of these').map(item => item.parameter) : [],//['Are Agri Banking specialists', 'Invests back into NZ', 'Supports rural communities', 'Is a brand I trust', 'Is a leader in supporting sustainable farming', 'Has staff based in rural communities', 'Is the best bank for Agri lending', 'Committed to agribusiness for the long term'],
        datasets: [
            {
                label: selfData ? selfData[0]['brand_name'] : '',
                data: selfData ? selfData.map(item => formatValue(item.value)) : [],
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderColor: '#f06b22',
                borderWidth: 3,
                pointRadius: window.innerWidth < 767 ? 13 : 18,
                pointHoverRadius: window.innerWidth < 767 ? 13 : 18,
                pointBackgroundColor: '#f06b22',
                type: 'scatter',
                borderRadius: 4,
                pointStyle: 'rectRounded',
                tension: 0.4,
                datalabels: {
                    align: 'center',
                    anchor: 'center',
                    color: '#fff',
                    font: {
                        size: window.innerWidth < 767 ? 10 : 14,
                        weight: 'bold',
                        family: 'VisbyCF-Bold, sans-serif',
                        letterSpacing: 1.5,
                    },
                }
            },
            {
                label: competitorData ? competitorData[0]['comp_name'] : '',
                data: competitorData ? competitorData.map(item => formatValue(item.value)) : [],
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderColor: '#dae8d8',
                borderWidth: 3,
                pointRadius: window.innerWidth < 767 ? 13 : 16, 
                pointHoverRadius: window.innerWidth < 767 ? 13 : 16,
                pointBackgroundColor: '#dae8d8',
                type: 'scatter',
                borderRadius: 4,
                pointStyle: 'rectRounded',
                datalabels: {
                    color: '#000',
                    font: {
                        size: window.innerWidth < 767 ? 8 : 10,
                        family: 'VisbyCF-medium, sans-serif',
                        letterSpacing: 1.5,
                    },
                }
            }
        ]
    };

    const splitTextIntoChunks = (text, chunkSize) => {
        const words = text ? text.split(' ') : '';
        const chunks = [];

        for (let i = 0; i < words.length; i += chunkSize) {
            chunks.push(words.slice(i, i + chunkSize).join(' '));
        }

        return chunks;
    };

    const dottedLinePlugin = {
        id: 'dottedLinePlugin',
        beforeDraw(chart) {
            const { ctx, scales: { y, x }, chartArea: { left, right } } = chart;
            const selfDataset = chart.data.datasets[0].data;
            const competitorDataset = chart.data.datasets[1].data; 
    
            ctx.save();
            
            ctx.strokeStyle = 'grey';
            ctx.lineWidth = 1.5;
            ctx.setLineDash([1, 1.5]);
    
            const yAxisTicks = y.getTicks();
            yAxisTicks.forEach((tick, index) => {
                const yPosition = y.getPixelForTick(index) - 1;
                if (yPosition !== undefined && !isNaN(yPosition)) {
                    ctx.beginPath();
                    ctx.moveTo(left, yPosition);
                    ctx.lineTo(right, yPosition);
                    ctx.stroke();
                    //console.log("Dotted Line Y Position for tick", index, ":", yPosition);
                }
            });
    
            ctx.setLineDash([]); 
            ctx.lineWidth = 3.5; 
            ctx.strokeStyle = '#dae8d8';
    
            selfDataset.forEach((selfValue, index) => {
                const competitorValue = competitorDataset[index];
                const yPosition = y.getPixelForTick(index) - 1; 
                if (yPosition !== undefined && !isNaN(yPosition)) {
                    if (selfValue !== undefined && competitorValue !== undefined) {
                        const selfX = x.getPixelForValue(selfValue);
                        const competitorX = x.getPixelForValue(competitorValue);
    
                        if (!isNaN(selfX) && !isNaN(competitorX)) {
                            ctx.beginPath();
                            ctx.moveTo(selfX, yPosition-1);
                            ctx.lineTo(competitorX, yPosition-1);
                            ctx.stroke();
                        }
                    }
                }
            });
            
            ctx.restore();
        }
    };
    
    const options = {
        indexAxis: 'y', // Horizontal bar chart layout
        responsive: true,
        animation: true,
        maintainAspectRatio: window.innerWidth < 767 ? false : true,
        devicePixelRatio: window.devicePixelRatio || 1,
        scales: {
            x: {
                beginAtZero: true,
                // max: 100,
                max: 105,  
                grid: {
                    display: false // Remove grid lines

                },
                ticks: {
                    display: false,
                    color: 'white', // X-axis label color
                    font: {
                        family: 'VisbyCF-medium, sans-serif',
                        letterSpacing: 1.5,
                    }
                },
                border: {
                    display: false, // Remove the x-axis line
                },
            },
            y: {
                grid: {
                    display: false, // Remove grid lines
                    // drawBorder: false,
                    offset: false,
                    color: function (context) {
                        return context.tick && context.tick.label ? '#4f5c50' : 'transparent'; // Show grid line only in the center of labels
                    },
                    // drawTicks: false,
                    lineWidth: 0.3,
                },
                ticks: {
                    color: '#000', // Y-axis label color
                    // padding: 10,
                    // callback: function(value) {
                    //     // Split the label into multiple lines by returning an array
                    //     const label = this.getLabelForValue(value);
                    //     const words = label.split(" ");
                    //     const maxWordsPerLine = 3; // Maximum words per line
                    //     let lines = [];

                    //     for (let i = 0; i < words.length; i += maxWordsPerLine) {
                    //       lines.push(words.slice(i, i + maxWordsPerLine).join(" "));
                    //     }

                    //     return lines; // Return array of strings for multiline display
                    // },
                    align: window.innerWidth < 767 ? 'start' : 'start', // Align left for mobile, center for desktop
                    padding: window.innerWidth < 767 ? 6 : 12,
                    crossAlign: 'far', //align the labels to left 
                    // callback: function (value) {
                    //     const label = this.getLabelForValue(value);
                        
                    //     // Insert spaces between characters for Y-axis labels only
                    //     const spacedLabel = label.split('').join(' ');
        
                    //     // Handle responsive behavior for smaller screens
                    //     if (window.innerWidth < 767) {
                    //         let lines = [];
                    //         let words = spacedLabel.split(" ");
                    //         let currentLine = "";
        
                    //         for (let word of words) {
                    //             // Check if adding the next word exceeds the limit
                    //             if (currentLine.length + word.length + 1 <= 20) {
                    //                 currentLine += (currentLine.length > 0 ? " " : "") + word; // Add space before word if current line is not empty
                    //             } else {
                    //                 lines.push(currentLine); // Push the current line to lines array
                    //                 currentLine = word; // Start a new line with the current word
                    //             }
                    //         }
        
                    //         if (currentLine.length > 0) {
                    //             lines.push(currentLine); // Push the last line if any
                    //         }
        
                    //         return lines; // Return an array of strings for multiline display
                    //     }
                        
                    //     return spacedLabel; // Return spaced label for larger screens
                    // },
                    // callback: function (value) {
                    //     const label = this.getLabelForValue(value);
                    
                    //     // Insert spaces between characters for Y-axis labels only
                    //     const spacedLabel = label.split('').join('\u2009');// Apply letter spacing by adding a space between characters
                    
                    //     const words = spacedLabel.split(' ');
                    //     let lines = [];
                    //     let currentLine = '';
                    
                    //     // Set a character limit for each line
                    //     const maxLineLength = 414; // Adjust based on your design preference
                    
                    //     for (let i = 0; i < words.length; i++) {
                    //         const word = words[i];
                    
                    //         // Check if adding the next word exceeds the max line length
                    //         if (currentLine.length + word.length + 1 > maxLineLength) {
                    //             // Only push the current line if it's not empty
                    //             if (currentLine.length > 0) {
                    //                 lines.push(currentLine); // Push current line to lines array
                    //                 currentLine = ''; // Reset current line
                    //             }
                    
                    //             // Check if the word ends with a space and is longer than the max length
                    //             if (word.length > maxLineLength && word.endsWith(' ')) {
                    //                 // Split long words into smaller chunks
                    //                 let subWords = word.match(new RegExp('.{1,' + maxLineLength + '}', 'g')) || [];
                    //                 for (let subWord of subWords) {
                    //                     lines.push(subWord.trim()); // Push each chunk as a new line, removing any trailing space
                    //                 }
                    //             } else {
                    //                 // If the current line is empty, we can safely assign the word to it
                    //                 currentLine = word; // Start a new line with the current word
                    //             }
                    //         } else {
                    //             // If the word fits, add it to the current line
                    //             currentLine += (currentLine.length > 0 ? ' ' : '') + word; // Add space before word if not empty
                    //         }
                    //     }
                    
                    //     // Push the last line if there are any remaining words
                    //     if (currentLine.length > 0) {
                    //         lines.push(currentLine);
                    //     }
                    
                    //     // Handle responsive behavior for smaller screens
                    //     if (window.innerWidth < 767) {
                    //         return lines.slice(0, 2); // Show only the first two lines on smaller screens
                    //     }
                    
                    //     return lines; // Return the multiline labels
                    // },
                    font: (context) => {
                        const width = context.chart.width;
                        return {
                            size: width < 767 ? 10 : 12, // Responsive font size for Y-axis
                            family: 'VisbyCF-medium, sans-serif',
                            letterSpacing: '1.5px',
                        };
                    },
                },
                border: {
                    display: false,
                },
                min: -2,
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                font: (context) => {
                    const width = context.chart.width;
                    return {
                        size: width < 767 ? 10 : 12, // Responsive font size
                        family: 'VisbyCF-medium, sans-serif',
                        letterSpacing: 1.5,
                    };
                },
                formatter: (value) => value.toFixed() + '%',
                
            },
            tooltip: {
                callbacks: {
                    title: function (tooltipItem) {
                        const additionalText = `Do you associate the following with this bank - '${tooltipItem[0].label}.'`;
                        return ` ${additionalText}`;
                        // const textChunks = splitTextIntoChunks(additionalText, 10);
                        // return [...textChunks];
                    },
                    label: function (tooltipItem) {

                        return `${tooltipItem.dataset.label}: ${tooltipItem.raw}%`;
                    }
                },
                titleFont: {
                    family: 'VisbyCF-thin, sans-serif',
                    letterSpacing: 1.5,
                    size: 12, 
                },
                bodyFont: {
                    family: 'VisbyCF-medium, sans-serif',
                    letterSpacing: 1.5,
                    size: 11 
                },
                footerFont: {
                    family: 'VisbyCF-medium, sans-serif',
                    letterSpacing: 1.5,
                    size: 10 
                }
            },
        },

        layout: {
            padding: {
                top: 0, //'5%',
                right: 20, //'5%',
                bottom: 20, //'5%',
                // left: 20, //'5%'
                left: 12,
            }
        }
    };

    return (
        <div className="App" style={{ height: 'auto', width: "100%" }}>
            <Bar data={data} options={options} plugins={[dottedLinePlugin]} />
        </div>
    );
}