import * as Yup from 'yup';
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import axios from "axios";
import Swal from "sweetalert2";
import Header from "../Header/Header";

export default function Profile(){
    const [imgSrc, setImgSrc] = useState('assets/img/avatars/1.png')
    const authUser = localStorage.getItem('ag-authUser');
    const user = JSON.parse(authUser);
    const inputFile = useRef(null);

    const onFileChange = (file) => {
        formik.setFieldValue('image', file);
        const reader = new FileReader();
        reader.onload = () => {
        setImgSrc(reader.result);
        // console.log(reader.result)
        // formik.setFieldValue('image', reader.result);
        }
        reader.readAsDataURL(file);
    };

    const resetImage = () => {
        if (inputFile.current) {
            inputFile.current.value = "";
            inputFile.current.type = "text";
            inputFile.current.type = "file";
        }
        setImgSrc('assets/img/avatars/1.png');
        formik.setFieldValue('image','');
    };

    useEffect(() => {
        setImgSrc(imgSrc)
    },[imgSrc]);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Firstname is required'),
        email: Yup.string()
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            'Enter a valid email address'
        )
        .required('Email is required'),
    });

    const initialValues = {
        id: user.id,
        name: user.name,
        lastName: user.lastName || '',
        email: user.email,
        phone: user.phone,
        image: '',
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);
        }
    });

    useEffect(() => {
        if (Object.keys(user).length !== 0) {
            setImgSrc(user.image ? user.image : 'assets/img/avatars/1.png');
            formik.setValues({
                id: user.id,
                name: user.firstName,
                lastName: user.lastName || '',
                email: user.email,
                phone: user.phone ? user.phone :'',
                image: null,
            });
        }
    }, [formik.setValues]);

    const handleSubmit = async (values) => {
        try {
            const formData = new FormData();
            formData.append('id', values.id);
            formData.append('name', values.name);
            formData.append('lastName', values.lastName);
            formData.append('email', values.email);
            formData.append('phone', values.phone);
    
            // Only append the image if it's been updated (i.e., it's not null)
            if (values.image !== '') { // Adjust this condition based on your backend handling
                formData.append('image', values.image);
            } else {
                formData.append('image', ''); // Send empty string to indicate image reset
                setImgSrc('assets/img/avatars/1.png')
            }
    
            let res = await axios.post('/api/users/updateProfile', formData);
            if (res.data.success) {
                const authUserJSON = localStorage.getItem('ag-authUser');
                let authUser = JSON.parse(authUserJSON);
    
                if (authUser) {
                    if (values.image) {
                        authUser.image = res.data.data.image;
                    }
                    authUser.firstName = res.data.data.firstName;
                    authUser.lastName = res.data.data.lastName;
                    authUser.email = res.data.data.email;
                    authUser.phone = res.data.data.phone;
    
                    try {
                        localStorage.setItem('ag-authUser', JSON.stringify(authUser));
                    } catch (error) {
                        console.error("Error storing authUser:", error);
                    }
                } else {
                    console.error("authUserJSON is not valid JSON:", authUserJSON);
                }
    
                Swal.fire(res.data.message, '', 'success').then(result => {
                    window.location.reload();
                });
            } else {
                Swal.fire(res.data.message, '', 'info');
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                Swal.fire('Error updating profile:', error.response.data.message, 'error');
            } else {
                Swal.fire('Error updating profile:', error.message, 'error');
            }
        }
    };
    
    
    return(
        <>
            <Header />    
            <section className="main-content">
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row" style={{alignItems: 'center',justifyContent: 'center'}}>
                        <div className="col-md-10">
                            <ul className="nav nav-pills ss-nav-pills flex-sm-column flex-md-row mb-3">
                                <li className="nav-item">
                                    <a className="nav-link active"><i className="fa fa-user me-1"></i> Account</a>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/change-password"><i className="fa fa-key me-1"></i> Change Password</Link>
                                </li>
                            </ul>
                            <form id="formAccountSettings" method="POST" onSubmit={formik.handleSubmit} encType="multipart/form-data" >
                                <div className="card mb-4">
                                    <h5 className="card-header border-0">Profile Details</h5>
                                    <div className="card-body">
                                        <div className="d-flex align-items-start align-items-sm-center gap-4">
                                            <img src={imgSrc} alt="user-avatar" className="d-block rounded" height="100" width="100" id="uploadedAvatar" />
                                            <div className="button-wrapper">
                                                <label htmlFor="upload" className="me-2 mb-4 save-btn" tabIndex="0" style={{cursor: 'pointer'}}>
                                                    <span className="d-none d-sm-block">Upload new photo</span>
                                                    <i className="fa-solid fa-arrow-up-from-bracket d-block d-sm-none"></i>
                                                    <input type="file" id="upload" className="account-file-input" hidden accept="image/png, image/jpeg" name='image'
                                                    onChange={(e) => onFileChange(e.target.files[0])} 
                                                    ref={inputFile}/>
                                                </label>
                                                <button type="button" className="add-btn account-image-reset mb-4" onClick={resetImage}>
                                                    <i className="fa-solid fa-rotate-right d-block d-sm-none"></i>
                                                    <span className="d-none d-sm-block">Reset</span>
                                                </button>
                                                <p className="text-muted mb-0 fw-semibold" style={{fontSize: "11px"}}>Allowed JPG, GIF or PNG. Max size
                                                of 800K</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="my-0" />
                                    <div className="card-body">
                                            <div className="row">
                                                <div className="mb-3 col-md-6">
                                                    <label htmlFor="name" className="form-label">First Name</label>
                                                    <input className="form-control" type="text" id="name" name="name"
                                                    onChange={formik.handleChange} 
                                                    onBlur={formik.handleBlur} 
                                                    value={formik.values.name}
                                                    placeholder="Enter your firstname" autoFocus />
                                                    {formik.touched.name && formik.errors.name && (
                                                        <div className="text-danger errorMSG">{formik.errors.name}</div>
                                                    )}
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label htmlFor="lastName" className="form-label">Last Name</label>
                                                    <input className="form-control" type="text" id="lastName" name="lastName"
                                                    onChange={formik.handleChange} 
                                                    onBlur={formik.handleBlur} 
                                                    value={formik.values.lastName}
                                                    placeholder="Enter your lastName" autoFocus />
                                                    {formik.touched.lastName && formik.errors.lastName && (
                                                        <div className="text-danger errorMSG">{formik.errors.lastName}</div>
                                                    )}
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label htmlFor="email" className="form-label">Email Id</label>
                                                    <input className="form-control" type="email" id="email" name="email"                                        
                                                    onChange={formik.handleChange} 
                                                    onBlur={formik.handleBlur} 
                                                    value={formik.values.email}
                                                    placeholder="Enter your email" />
                                                    {formik.touched.email && formik.errors.email && (
                                                        <div className="text-danger errorMSG">{formik.errors.email}</div>
                                                    )}
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label" htmlFor="phone">Phone Number</label>
                                                    <div className="input-group input-group-merge">
                                                        <span className="input-group-text">AU (+61)</span>
                                                        <input className="form-control" type="text" id="phone" name="phone"                                 
                                                        onChange={formik.handleChange} 
                                                        onBlur={formik.handleBlur} 
                                                        value={formik.values.phone}
                                                        placeholder="Enter your Phone Number" />
                                                    </div>
                                                    {formik.touched.phone && formik.errors.phone && (
                                                        <div className="text-danger errorMSG">{formik.errors.phone}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="mt-2">
                                                <button type="submit" className="save-btn">Save changes</button>
                                                {/* <button type="reset" className="btn btn-outline-secondary">Cancel</button> */}
                                            </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}