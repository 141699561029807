import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useFormik } from "formik";
import axios from "axios";
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';

const validationSchema = Yup.object().shape({
    competitors: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Competitor is required')
      })
    ).min(1, 'At least one competitor is required'),
});

export default function AddCompetitors({type, editId, show, setShow, loading, setLoading, getList, brandId, setBrandId, ...rest}){
    const [competitors, setCompetitors] = useState([{ id: '', name: '' }]);
    const initialValues = {
        id: editId,
        brandId: brandId,
        competitors: competitors
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    useEffect(() => {
        if (brandId !== null){
            formik.setFieldValue('brandId', brandId);
        }
    },[]);
    
    useEffect(() => {
        if(editId > 0){
            axios.get(`api/competitor?brandId=${brandId}`) 
            .then(res => {
                if (res.data.success) {
                    const competitorData = res.data.data;
                    if(competitorData.length > 0 ){
                        formik.setValues({
                            // id: competitorData.id,
                            // brandId: brandId,
                            competitors: competitorData.map(competitor => ({ id: competitor.id, name: competitor.name }))
                        });
                        setCompetitors(competitorData.map(competitor => ({ id: competitor.id, name: competitor.name })));
                    }
                    else{
                        setCompetitors([{ id: '', name: '' }])
                    }
                } else {
                    console.error("Error fetching competitor:", res.data.message);
                }
            })
            .catch(error => {
                console.error("Error fetching competitor:", error.message);
            });
        }  else {
            formik.resetForm();
            setCompetitors([{ id: '', name: '' }]);
        }
    },[show, brandId]);

    const handleAddcompetitor = () => {
        setCompetitors([...competitors, { id: '', name: '' }]);
    };

    const handleRemoveCompetitor = (index) => {
        const competitorId = competitors[index].id;
        if(competitorId === ''){
            setCompetitors(competitors.filter((_, i) => i !== index));
            formik.setFieldValue('competitors', competitors.filter((_, i) => i !== index));
            return;
        }
        else{
            Swal.fire({
                title: 'Are you sure you want to delete this competitor?',
                showCancelButton: true,
                confirmButtonColor: '#087f43',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    const updatedCompetitors = competitors.filter((_, i) => i !== index);
                    setCompetitors(updatedCompetitors);
                    formik.setFieldValue('competitors', updatedCompetitors); 
                    axios.delete(`/api/competitor/delete?id=${competitorId}`).then((res) =>{
                        if(res.data.success){
                            Swal.fire('Deleted!', '', 'success')
                            // setCompetitors(competitors.filter((_, i) => i !== index));
                        } 
                    }).catch((error) => {
                        console.log(error);                     
                        Swal.fire(error.message, '', 'info')           
                    });
                } else if (result.isDenied) {
                Swal.fire('Cancelled!', '', 'info')
                }
            })
        }
    };

    const handleChange = (index, event) => {
        const newCompetitors = [...competitors];
        newCompetitors[index] = {
            ...newCompetitors[index],
            name: event.target.value
        };
        setCompetitors(newCompetitors);
        formik.setFieldValue('competitors', newCompetitors);
    };

    const handleClose = () => setShow(false);

    const handleCloseModal = () => {
        formik.resetForm(); 
        handleClose(); 
    };

    const handleSubmit = (values) => {
        if(brandId === null){
            Swal.fire('Brand Id cannot be null', '', 'warning');
            return; 
        }
        values.brandId = brandId
        setLoading(true);
        const apiCall = editId > 0 ? axios.put : axios.post;
        const apiUrl = editId > 0 ? `api/competitor/update` : `api/competitor/create`;
        apiCall(apiUrl, values).then(res => {
            setLoading(false);
            setShow(false);
            if (res.data.success) {
                Swal.fire(res.data.message, '', 'success').then(() => {
                    getList();
                    formik.resetForm();
                });
                // if (editId < 0 && onSuccess) {
                //     onSuccess();
                // }
            } else {
                Swal.fire(res.data.message, '', 'error');
            }
        }).catch((error) => {
            setLoading(false);
            setShow(false);
            Swal.fire(error.response.data.message, '', 'error');
        });
    };
    
    return (
        <Modal show={show} onHide={handleCloseModal} size='lg' backdrop="static">
            <form autoComplete="off" onSubmit={formik.handleSubmit} encType="multipart/form-data">
                <Modal.Header>
                    <h1 className="modal-title" id="addUploadLabel">{type} Competitors</h1>
                    <Link className="ss-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
                        <i className="fa-solid fa-xmark"></i>
                    </Link>
                </Modal.Header>
                <Modal.Body>
                {competitors.map((competitor, index) => (
                    <div className="row" key={index}>
                        <div className="col-md-12">
                            <div className="mb-2">
                                <div className='row align-items-center'>
                                <div className="col-md-2">
                                        <label htmlFor={`competitor-${index}`} className="mb-0 form-label"> {`Competitor ${index + 1}`}</label>
                                    </div>
                                    <div  className="col-md-9">
                                        <input
                                            type="text"
                                            id={`competitor-${index}`}
                                            className="form-control"
                                            name={`competitors[${index}].name`}
                                            value={competitor.name}
                                            onChange={(event) => handleChange(index, event)}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.competitors && formik.errors.competitors && formik.errors.competitors[index] && (
                                            <div className="text-danger errorMSG">{formik.errors.competitors[index].name}</div>
                                        )}
                                    </div>
                                    {index === 0 ? (
                                        <div className='col-md-1'>
                                            <button type="button" className="save-btn" onClick={handleAddcompetitor} style={{padding: '7px 12px'}} >
                                                <i className="fa-solid fa-plus"></i>
                                            </button>
                                        </div>
                                    ) : (
                                        <div className='col-md-1'>
                                            <button type="button" className="save-btn bg-danger-subtle" onClick={() => handleRemoveCompetitor(index)} style={{padding: '7px 12px'}} >
                                                <i className="fa-solid fa-close text-danger"></i>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="add-btn" onClick={handleCloseModal}>
                        Close
                    </button>
                    <button type="submit" className="save-btn" disabled={loading}>
                        {loading ? 'Processing...' : 'Save'}
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}