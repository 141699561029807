import PropTypes from "prop-types";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";

export const Mapping = ({ show, setShow, handleClose, loading, setLoading, getList, brands, brandId, uploadId, ...rest}) => {

    const [parameters, setParameters] = useState([]);
    const [compititors, setCompititors] = useState([]);
    const [Q2, setQ2] = useState([]);
    const [Q7, setQ7] = useState([]);
    const [maxBrandQuestion, setMaxBrandQuestion] = useState(8);

    useEffect(() => {
        if(show === true && brandId !== null ){
            getPrametersList();
            getCompetitorsList();
        }
    }, [show, brandId]);

    useEffect(() => {
        if(show === true && uploadId !== null ){
            getUploadData();
            getSavedMappingsForCompletionPopup();
        }
    }, [show, uploadId]);
 
    const initialValues = {
        uploadId: uploadId,
        brandMappings: [],
        parameterMappings: [],
        maxBrandQuestion: maxBrandQuestion,
        questionBrandMappings: []
    }
    
    const validationSchema = Yup.lazy(values => {
        const schema = {};
        
        Q2.forEach((_, index) => {
            schema[`brandId-${index}`] = Yup.string().required('Brand is required');
        });
        
        Q7.forEach((_, index) => {
            schema[`parameterId-${index}`] = Yup.string().required('Parameter is required');
        });
        
        Array.from({ length: values.maxBrandQuestion - 7 + 1 }, (_, i) => 7 + i).forEach((num) => {
            schema[`questionBrandId-${num}`] = Yup.string().required('Brand is required');
        });
        
        return Yup.object().shape(schema);
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values, { resetForm }) => {
            handleSubmit(values).then(() => {
                resetForm();
            });
        },
    });

    const getCompetitorsList = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`/api/competitors?brandId=${brandId}`);
            setLoading(false);
            let result = response.data;
            if (result.success) {
                setCompititors(result.data);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            Swal.fire("Error in getting prameters List: " + err);
        }
    };

    const getPrametersList = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`/api/parameters?brandId=${brandId}`);
            setLoading(false);
            let result = response.data;
            if (result.success) {
                setParameters(result.data);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            Swal.fire("Error in getting prameters List: " + err);
        }
    };

    const getUploadData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`/api/mappings?uploadId=${uploadId}`);
            setLoading(false);
            let result = response.data;
            if (result.success) {
                setQ2(result.data.Q2Array);
                setQ7(result.data.Q7Array);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            Swal.fire("Error in getting prameters List: " + err);
        }
    };

    /* Retrieving the details for `brandId` from `brandMappings`, `parameterMappings`, 
    and `questionBrandMappings` tables basedon the `uploadId` that has previously been mapped. 
    This approach is currently implemented and using in Complete Mapping Popup.
    */
    const getSavedMappingsForCompletionPopup = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`/api/getSavedMappings/complete?uploadId=${uploadId}&brandId=${brandId}`);
            setLoading(false);
            let result = response.data;            
            if (result.success) {
                // console.log("result------------------------------------------",result.data);
                const formikValues = {};
                result.data.brandMappings.forEach((q2, index) => {
                    formikValues[`brandId-${index}`] = q2.brandId || '';
                });

                result.data.parameterMappings.forEach((q7, index) => {
                    formikValues[`parameterId-${index}`] = q7.parameterId || '';
                });
    
                result.data.questionBrandMappings.forEach((otherQuestion, index) => {
                    formikValues[`questionBrandId-${index + 7}`] = otherQuestion.brandId || '';
                });
                setMaxBrandQuestion(parseInt(result.data.questionBrandMappings.length) + 6)
                formikValues[`maxBrandQuestion`] = (parseInt(result.data.questionBrandMappings.length) + 6) || maxBrandQuestion;

                formik.setValues({
                    ...formik.values,
                    ...formikValues
                });
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            Swal.fire("Error in getting parameters mapping: " + err);
        }
    }

    const handleMaxBrandQuestionChange = (e) => {
        const newValue = Number(e.target.value);
        setMaxBrandQuestion(newValue);
        formik.setFieldValue('maxBrandQuestion', newValue);
    };

    // Helper to get selected brandIds for filtering
    const getSelectedBrandIds = () => {
        const selectedBrands = [];
        Q2.forEach((_, index) => {
            if (formik.values[`brandId-${index}`]) {
                selectedBrands.push(formik.values[`brandId-${index}`]);
            }
        });
        return selectedBrands;
    };

    // Helper to get selected parameterIds for filtering
    const getSelectedParameterIds = () => {
        const selectedParameters = [];
        Q7.forEach((_, index) => {
            if (formik.values[`parameterId-${index}`]) {
                selectedParameters.push(formik.values[`parameterId-${index}`]);
            }
        });
        return selectedParameters;
    };

    // Helper to get selected OtherQuestionsBrands for filtering
    const getSelectedOtherQuestionsBrandIds = () => {
        const selectedOtherQuestionsBrands = [];
        Array.from({ length: maxBrandQuestion - 7 + 1 }, (_, i) => 7 + i).forEach((num) => {
            if (formik.values[`questionBrandId-${num}`]) {
                selectedOtherQuestionsBrands.push(formik.values[`questionBrandId-${num}`]);
            }
        });
        return selectedOtherQuestionsBrands;
    };
    
    const handleSubmit = async (values)=>{
        setLoading(true);
        
        const brandMappings = Q2.map((q2, index) => ({
            questionName: q2,
            brandId: formik.values[`brandId-${index}`] || '',
        }));

        const parameterMappings = Q7.map((q7, index) => ({
            questionName: q7,
            parameterId: formik.values[`parameterId-${index}`] || '',
        }));

        const questionBrandMappings = Array.from({ length: values.maxBrandQuestion - 7 + 1 }, (_, i) => 7 + i).map((num) => ({
            questionName: `Q${num}`,
            brandId: formik.values[`questionBrandId-${num}`] || '',
        }));

        const dataToSave = {
            uploadId: uploadId,
            brandMappings,
            parameterMappings,
            questionBrandMappings,
        };
    
        try{
            let response = await axios.post(`/api/saveMappings`, dataToSave);
            setLoading(false);
            if(response.data.success){
                setShow(false);
                Swal.fire(response.data.message, '', 'success').then(result => { 
                    console.log('Data saved successfully:', result);            
                    getList()
                })
            }
            else {
                setShow(false);
                Swal.fire({
                    icon: 'error',
                    text: response.data.message || 'An error occurred while fetching data!',
                });
            }
        }catch(error) {
            setLoading(false);
            Swal.fire({
                icon: 'error',
                text: error.message || error || 'Failed to connect to the server. Please try again later.',
            });
        };          
    }
    
    const handleCloseModal = () => {
        formik.resetForm();
        handleClose();
        getList();
    };

    return (
        <Modal show={show} onHide={handleCloseModal} size="lg" backdrop="static">
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <Modal.Header>
                    <h1 className="modal-title" id="mappingScreenLabel"> Questions Mapping Screen</h1>
                    <a className="ss-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
                        <i className="fa-solid fa-xmark"></i>
                    </a>
                </Modal.Header>
                <Modal.Body className="modal-body-custom py-0">
                    <div className="card border-0">
                        <div className="card-body">
                            <div className="normHead">Brand Mapping For Prompted Awareness</div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        {Q2 && Array.isArray(Q2) && Q2.map((q2, index) => (
                            <div className="col-md-12 mb-2" key={index}>
                                {/* <div className="col-md-6"> */}
                                    <div className="row align-items-center">
                                        <div className="col-md-2"></div>
                                        <div className="col-md-2">
                                            <label className="form-label">{(index+1) +". "+ q2}</label>
                                        </div>
                                        <div className="col-md-6">
                                            <Autocomplete
                                                id={`brand-select-${index}`}
                                                name={`brandId-${index}`}
                                                className="autoComplete-select"
                                                size="small"
                                                style={{ backgroundColor: 'transparent', border: '0' }}
                                                // options={compititors}
                                                options={compititors.filter(brand => !getSelectedBrandIds().includes(brand.id))}
                                                getOptionLabel={(option) => option.name}
                                                value={
                                                    compititors.find(brand => brand.id === formik.values[`brandId-${index}`]) || null
                                                }
                                                onChange={(event, newValue) => {
                                                    formik.setFieldValue(`brandId-${index}`, newValue ? newValue.id : '');
                                                    formik.setFieldTouched(`brandId-${index}`, true); // Mark field as touched
                                                    // formik.validateField(`brandId-${index}`); //Trigger validation for the specific field
                                                }}
                                                renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className="form-control autoComplete-select"
                                                    placeholder="Select Brand"
                                                    // error={Boolean(formik.errors[`brandId-${index}`])}
                                                    // helperText={formik.errors[`brandId-${index}`]}
                                                />
                                                )}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                componentsProps={{
                                                    paper: {
                                                        sx: {fontFamily: 'VisbyCF-Medium', letterSpacing: '1px'},
                                                    },
                                                    popper: {
                                                        sx: {fontFamily: 'VisbyCF-Medium', letterSpacing: '1px'},
                                                    },
                                                }}
                                            />
                                            <div className="text-danger errorMSG">{formik.errors[`brandId-${index}`]}</div>

                                        </div>
                                        <div className="col-md-2"></div>
                                    </div>
                                {/* </div> */}
                            </div>
                        ))}
                    </div>

                    {/* <h5>Parameters Mappings</h5> */}
                    <div className="card border-0">
                        <div className="card-body">
                            <div className="normHead">Parameters Mappings</div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        
                        {Q7 && Array.isArray(Q7) && Q7.map((q7, index) => (
                            <div className="col-md-12 mb-2">
                                <div className="row align-items-center">
                                    
                                <div className="col-md-2"></div>
                                    <div className="col-md-2">
                                        <label className="form-label">{(index+1) +". "+ q7}</label>
                                    </div>
                                    <div className="col-md-6">
                                        <Autocomplete
                                            id={`parameter-select-${index}`}
                                            name={`parameterId-${index}`}
                                            className="autoComplete-select"
                                            size="small"
                                            style={{ backgroundColor: 'transparent', border: '0' }}
                                            // options={parameters}                                            
                                            options={parameters.filter(parameter => !getSelectedParameterIds().includes(parameter.id))}
                                            getOptionLabel={(option) => option.name}
                                            value={
                                                parameters.find(parameter => parameter.id === formik.values[`parameterId-${index}`]) || null
                                            }
                                            onChange={(event, newValue) => formik.setFieldValue(`parameterId-${index}`, newValue ? newValue.id : '')}
                                            renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                className="form-control autoComplete-select"
                                                placeholder="Select Parameter"
                                                // error={Boolean(formik.errors[`parameterId-${index}`])}
                                                // helperText={formik.errors[`parameterId-${index}`]}                    
                                            />
                                            )}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            componentsProps={{
                                                paper: {
                                                    sx: {fontFamily: 'VisbyCF-Medium', letterSpacing: '1px'},
                                                },
                                                popper: {
                                                    sx: {fontFamily: 'VisbyCF-Medium', letterSpacing: '1px'},
                                                },
                                            }}
                                        />
                                        <div className="text-danger errorMSG">{formik.errors[`parameterId-${index}`]}</div>
                                    </div>
                                    
                                    <div className="col-md-2"></div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* <h5>Brand Mappings</h5> */}
                    <div className="card border-0">
                        <div className="card-body p-1">
                            <div className="normHead mb-2">Brand Mappings</div>
                            <div className="d-flex align-items-center justify-content-center">
                                <p className="simText mb-0">
                                    Which of the following do you associate, applies on question Q7 to Q
                                </p>
                                <div style={{width: '10%'}}>
                                    <input
                                        className="form-control ms-1"
                                        type="number"
                                        min={8}
                                        value={maxBrandQuestion}
                                        onChange={handleMaxBrandQuestionChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        {/* <div className="row mb-2">
                            <div className="col-md-10">
                                <h6>Which of the following do you associate, applies on question Q7 to Q</h6>
                            </div>
                            <div className="col-md-2 float-left">
                                <input
                                    className="form-control"
                                    type="number"
                                    min={8}
                                    value={maxBrandQuestion}
                                    onChange={handleMaxBrandQuestionChange}
                                />
                            </div>
                        </div> */}
                    </div>
                    <div className="row mb-4">
                        {Array.from({ length: maxBrandQuestion - 7 + 1 }, (_, i) => 7 + i).map((num) => (
                        <div className="col-md-12 mb-2" key={num}>
                            <div className="row align-items-center">
                                
                                <div className="col-md-2"></div>
                                <div className="col-md-2">
                                    <label className="form-label"> {`Q${num}`} </label>
                                </div>
                                <div className="col-md-6">
                                    <Autocomplete
                                        id={`brand-select-${num}`}
                                        name={`questionBrandId-${num}`}
                                        className="autoComplete-select"
                                        size="small"
                                        style={{ backgroundColor: 'transparent', border: '0' }}
                                        // options={compititors}
                                        options={compititors.filter(compititor => !getSelectedOtherQuestionsBrandIds().includes(compititor.id))}
                                        getOptionLabel={(option) => option.name}
                                        value={
                                            compititors.find(compititor => compititor.id === formik.values[`questionBrandId-${num}`]) || null
                                        }
                                        onChange={(event, newValue) => formik.setFieldValue(`questionBrandId-${num}`, newValue ? newValue.id : '')}
                                        renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className="form-control autoComplete-select"
                                            placeholder="Select Brand"
                                            // error={Boolean(formik.errors[`questionBrandId-${num}`])}
                                            // helperText={formik.errors[`questionBrandId-${num}`]}
                                        />
                                        )}
                                        isOptionEqualToValue={(option, value) => option.id === value}
                                        componentsProps={{
                                            paper: {
                                                sx: {fontFamily: 'VisbyCF-Medium', letterSpacing: '1px'},
                                            },
                                            popper: {
                                                sx: {fontFamily: 'VisbyCF-Medium', letterSpacing: '1px'},
                                            },
                                        }}
                                    />
                                    <div className="text-danger errorMSG">{formik.errors[`questionBrandId-${num}`]}</div>
                                </div>                                
                                <div className="col-md-2"></div>
                            </div>
                        </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="reset" className="add-btn" onClick={handleCloseModal}>Close</button>       
                    <button type="submit" className="save-btn">Save</button>    
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default Mapping;
Mapping.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired    
};