import { useEffect, useState } from "react";

import Header from "../Header/Header";
import Loader from "../Loader/Loader";

import axios from "axios";
import Swal from 'sweetalert2';

import Nav from "./Filters/Nav";
import TimelineFilters from "./Filters/TimelineFilters";
import AttributesFilter from "./Filters/AttributesFilter";
import TimelineChart from "./Charts/TimelineChart";
import CompetitorFilters from "./Filters/CompetitorFilters";
import MonthYearFilters from "./Filters/MonthYearFilters";

export default function Report() {
    const authUser = localStorage.getItem('ag-authUser');
    const user = JSON.parse(authUser);

    const [loading, setLoading] = useState(false);
    const [month, setMonth] = useState('1');
    const [year, setYear] = useState(new Date().getFullYear());
    // const [year, setYear] = useState('');
    // const [monthYearOptions, setMonthYearOptions] = useState([]);
    // const [selectedMonthYear, setSelectedMonthYear] = useState(null);

    // const [selectedAttribute, setSelectedAttribute] = useState('');    
    const [selectedAttributes, setSelectedAttributes] = useState({
        "all": false,
        "Northland": false,
        "Southland": false,
        "Dairy": false,
        "SheepAnBeef": false,
        "upto250ha": false,
        "over250ha": false,
        "under45": false,
        "over45": false
    });
    const [selectedCompetitor, setSelectedCompetitor] = useState('');
    const [selectedTimeLineFilter, setSelectedTimeLineFilter] = useState('Funnel');
    const [selectedTimelineOption, setSelectedTimelineOption] = useState('Unprompted Awareness');

    const [data, setData] = useState([]);

    useEffect(() => {

        if (month && selectedCompetitor && selectedTimelineOption) {
            getReport(month, selectedAttributes, selectedCompetitor, selectedTimelineOption);
        }
    }, [month, selectedAttributes, selectedCompetitor, selectedTimelineOption])

    // useEffect(() => {
    //     if (monthYearOptions.length > 0) {
    //         const latestMonthYear = monthYearOptions[0];
    //         setSelectedMonthYear(latestMonthYear);
    //         setMonth(latestMonthYear.month);
    //         setYear(latestMonthYear.year);
    //     }
    // }, [monthYearOptions]);

    const getReport = async (month, attribute, competitor, timelineFilterOption,) => {

        let condition = `?self=${user.brandId}`;
        month && (condition += `&month=${month}`);
        // year && (condition += `&year=${year}`);
        attribute && (condition += `&attribute=${JSON.stringify(attribute)}`);
        competitor && (condition += `&competitor=${competitor}`);
        selectedTimeLineFilter && (condition += `&selectedTimeLineFilter=${selectedTimeLineFilter}`);
        timelineFilterOption && (condition += `&timelineFilterOption=${timelineFilterOption}`);

        try {
            setLoading(true);
            const response = await axios.get(`/api/timelineReport${condition}`);
            setLoading(false);
            let result = response.data;
            if (result.success) {
                setData(result.data);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            Swal.fire("Error in getting prameters List: " + err);
        }
    };

    // const handleMonthAndYearChange1 = (event, newValue) => {
    //     if (newValue) {
    //         setSelectedMonthYear(newValue);
    //         setMonth(newValue.month);
    //         setYear(newValue.year);
    //     }
    //     else {
    //         setSelectedMonthYear(null);
    //         setMonth(null);
    //         setYear(null);
    //     }
    // };

    const handleMonthAndYearChange = (selectedValue) => {
        console.log("selectedValue-----------------", selectedValue);
        if (selectedValue) {
            getReport(selectedValue);
        }
    };

    // const handleAttributeChange = (event) => {
    //     setSelectedAttribute(event.target.value);
    // };

    const handleCompetitorChange = (event) => {
        setSelectedCompetitor(event.target.value);
    };

    const handleTimelineFilterChange = (value) => {
        setSelectedTimeLineFilter(value);

        if (value === 'Funnel') {
            setSelectedTimelineOption('Unprompted Awareness')
        } else if (value === 'Attributes') {
            setSelectedTimelineOption('Are Agri Banking specialists');
        } else if (value === 'NPS') {
            setSelectedTimelineOption('NPS');
        }
    };

    return (
        <>
            <Loader loading={loading} />
            <Header />
            <div className="ssDiv" style={{ backgroundColor: '#dae8d8' }}>
                <section className="main-content ss-content">
                    <div className="container">
                        {/* <Nav /> */}
                        <div className="card bg-CardColor">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12 mb-15">
                                        <div className="d-flex align-items-center justify-content-between tabHeading">
                                            <h3><span className="main_bank-span">{user?.brand?.name || ''} </span>
                                                {selectedTimeLineFilter === 'Funnel'
                                                    ? 'Performance Over Time'
                                                    : selectedTimeLineFilter === 'Attributes'
                                                        ? 'Attributes Over Time'
                                                        : 'NPS Over Time'}
                                            </h3>
                                            <div className="ms-auto d-none d-md-block">
                                                <div className="d-flex align-items-center">
                                                    <div className="radioBtn timelineFilter ss-content-2">
                                                        <div className="col">
                                                            <input type="radio" className="btn-check" name="timelineFilter" id="timelinefunnel" autoComplete="off" onChange={() => handleTimelineFilterChange('Funnel')} checked={selectedTimeLineFilter === 'Funnel'} />
                                                            <label className="btn" htmlFor="timelinefunnel">Funnel</label>
                                                        </div>
                                                        <div className="col">
                                                            <input type="radio" className="btn-check" name="timelineFilter" id="timelineattributes" autoComplete="off" onChange={() => handleTimelineFilterChange('Attributes')} checked={selectedTimeLineFilter === 'Attributes'} />
                                                            <label className="btn" htmlFor="timelineattributes">Attributes</label>
                                                        </div>
                                                        <div className="col">
                                                            <input type="radio" className="btn-check" name="timelineFilter" id="timelinenps" autoComplete="off" onChange={() => handleTimelineFilterChange('NPS')} checked={selectedTimeLineFilter === 'NPS'} />
                                                            <label className="btn" htmlFor="timelinenps">NPS</label>
                                                        </div>
                                                    </div>
                                                    <div className="selectLable ms-2 selectWidth">
                                                        <MonthYearFilters
                                                            // brandId={user.brandId}
                                                            handleMonthAndYearChange={handleMonthAndYearChange}
                                                            // monthYearOptions={monthYearOptions}
                                                            // setMonthYearOptions={setMonthYearOptions}
                                                            // selectedMonthYear={selectedMonthYear}
                                                            month={month}
                                                            setMonth={setMonth}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>                                    
                                    <AttributesFilter
                                        selectedAttributes={selectedAttributes}
                                        setSelectedAttributes={setSelectedAttributes}
                                    />
                                    <div className="col-6 mb-15 selectLable d-md-none d-sm-block">
                                        <MonthYearFilters
                                            // brandId={user.brandId}
                                            handleMonthAndYearChange={handleMonthAndYearChange}
                                            // monthYearOptions={monthYearOptions}
                                            // setMonthYearOptions={setMonthYearOptions}
                                            // selectedMonthYear={selectedMonthYear}
                                            month={month}
                                            setMonth={setMonth}
                                        />
                                    </div>
                                    <div className="col-12 d-md-none d-sm-block mb-15">
                                        <div className="radioBtn timelineFilter">
                                            <div className="col">
                                                <input type="radio" className="btn-check" name="timelineFilters" id="timelinefunnel" autoComplete="off" onChange={() => handleTimelineFilterChange('Funnel')} checked={selectedTimeLineFilter === 'Funnel'} />
                                                <label className="btn" htmlFor="timelinefunnel">Funnel</label>
                                            </div>
                                            <div className="col">
                                                <input type="radio" className="btn-check" name="timelineFilters" id="timelineattributes" autoComplete="off" onChange={() => handleTimelineFilterChange('Attributes')} checked={selectedTimeLineFilter === 'Attributes'} />
                                                <label className="btn" htmlFor="timelineattributes">Attributes</label>
                                            </div>
                                            <div className="col">
                                                <input type="radio" className="btn-check" name="timelineFilters" id="timelinenps" autoComplete="off" onChange={() => handleTimelineFilterChange('NPS')} checked={selectedTimeLineFilter === 'NPS'} />
                                                <label className="btn" htmlFor="timelinenps">NPS</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 col-12 mb-0 mb-md-3">
                                    {/* {console.log("selectedTimeLineFilter",selectedTimeLineFilter)}
                                        {selectedTimeLineFilter === 'Attributes' ?
                                            <>{console.log("if")}
                                         <CompetitorFilters selectedCompetitor={selectedCompetitor} setSelectedCompetitor={setSelectedCompetitor} handleCompetitorChange={handleCompetitorChange} setLoading={setLoading}
                                         month={month}
                                         year={year} /></>
                                         :
                                         <>{console.log("if")}

                                            <CompetitorFilters selectedCompetitor={selectedCompetitor} setSelectedCompetitor={setSelectedCompetitor} handleCompetitorChange={handleCompetitorChange} setLoading={setLoading} /></>
                                        } */}
                                        {selectedTimeLineFilter === 'Attributes' ? (
                                            <>
                                                {console.log("Attributes selected")}
                                                <CompetitorFilters
                                                key={`attributes-${selectedTimeLineFilter}`}
                                                selectedCompetitor={selectedCompetitor}
                                                setSelectedCompetitor={setSelectedCompetitor}
                                                handleCompetitorChange={handleCompetitorChange}
                                                setLoading={setLoading}
                                                month={month}
                                                year={year}
                                                />
                                            </>
                                            ) : (
                                            <>
                                                {console.log("Other selected")}
                                                <CompetitorFilters
                                                key={`other-${selectedTimeLineFilter}`}
                                                selectedCompetitor={selectedCompetitor}
                                                setSelectedCompetitor={setSelectedCompetitor}
                                                handleCompetitorChange={handleCompetitorChange}
                                                setLoading={setLoading}
                                                />
                                            </>
                                            )}
                                    </div>
                                    {/* <div className="col-md-12 mb-3">
                                        <div className="filterRadioButton radioBtn">
                                            <div className="col">
                                                <input type="radio" class="btn-check" name="options" id="option-Rabobank" autocomplete="off" checked />
                                                <label class="btn" for="option-Rabobank">Rabobank</label>
                                            </div>
                                            <div className="col">
                                                <input type="radio" class="btn-check" name="options" id="option-ASB" autocomplete="off" />
                                                <label class="btn" for="option-ASB">ASB</label>
                                            </div>
                                            <div className="col">
                                                <input type="radio" class="btn-check" name="options" id="option-ANZ" autocomplete="off" />
                                                <label class="btn" for="option-ANZ">ANZ</label>
                                            </div>
                                            <div className="col">
                                                <input type="radio" class="btn-check" name="options" id="option-BNZ" autocomplete="off" />
                                                <label class="btn" for="option-BNZ">BNZ</label>
                                            </div>
                                            <div className="col">
                                                <input type="radio" class="btn-check" name="options" id="option-Kiwibank" autocomplete="off" />
                                                <label class="btn" for="option-Kiwibank">Kiwibank</label>
                                            </div>
                                            <div className="col">
                                                <input type="radio" class="btn-check" name="options" id="option-TSB" autocomplete="off" />
                                                <label class="btn" for="option-TSB">TSB</label>
                                            </div>
                                            <div className="col">
                                                <input type="radio" class="btn-check" name="options" id="option-Westpac" autocomplete="off" />
                                                <label class="btn" for="option-Westpac">Westpac</label>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                {selectedTimeLineFilter === 'Funnel' ?
                                                    <div className="bankRadio tilelineFilter mb-sm-3 mb-md-0">
                                                        <div className="col">
                                                            <input type="radio" className="btn-check" name="timelineFunnelUnprompted" id="timelineFunnelUnprompted" autoComplete="off" onChange={() => setSelectedTimelineOption('Unprompted Awareness')} checked={selectedTimelineOption === 'Unprompted Awareness'} />
                                                            <label className="btn" htmlFor="timelineFunnelUnprompted" title="When thinking about businesses that provide banking and financial services, which providers or brands first come to mind/are you aware of?">Unprompted Awareness</label>
                                                        </div>
                                                        <div className="col">
                                                            <input type="radio" className="btn-check" name="timelineFunnelPrompted" id="timelineFunnelPrompted" autoComplete="off" onChange={() => setSelectedTimelineOption('Prompted awareness')} checked={selectedTimelineOption === 'Prompted awareness'} />
                                                            <label className="btn" htmlFor="timelineFunnelPrompted" title="From the list below, please select all the banking and financial services providers or brands you have heard of before today, even if you listed them earlier:">Prompted Awareness</label>
                                                        </div>
                                                        <div className="col">
                                                            <input type="radio" className="btn-check" name="timelineFunnelConsideration" id="timelineFunnelConsideration" autoComplete="off" onChange={() => setSelectedTimelineOption('Consideration')} checked={selectedTimelineOption === 'Consideration'} />
                                                            <label className="btn" htmlFor="timelineFunnelConsideration" title="Which of the following banking or financial service providers would you consider using in the future?">Consideration</label>
                                                        </div>
                                                        <div className="col">
                                                            <input type="radio" className="btn-check" name="timelineFunnelInvestigation" id="timelineFunnelInvestigation" autoComplete="off" onChange={() => setSelectedTimelineOption('Investigation')} checked={selectedTimelineOption === 'Investigation'} />
                                                            <label className="btn" htmlFor="timelineFunnelInvestigation" title="Before today, which of the following banking or financial service providers have you looked into or researched?">Investigation</label>
                                                        </div>
                                                        <div className="col">
                                                            <input type="radio" className="btn-check" name="timelineFunnelMainBankforBorrowing" id="timelineFunnelMainBankforBorrowing" autoComplete="off" onChange={() => setSelectedTimelineOption('Main bank for borrowing')} checked={selectedTimelineOption === 'Main bank for borrowing'} />
                                                            <label className="btn" htmlFor="timelineFunnelMainBankforBorrowing" title="And which banking and financial service provider do you use currently for the majority of your agribusiness borrowing?">Main bank for borrowing</label>
                                                        </div>
                                                        <div className="col">
                                                            <input type="radio" className="btn-check" name="timelineFunnelPreference" id="timelineFunnelPreference" autoComplete="off" onChange={() => setSelectedTimelineOption('Preference')} checked={selectedTimelineOption === 'Preference'} />
                                                            <label className="btn" htmlFor="timelineFunnelPreference" title="Which of the following is your preferred bank?">Preference</label>
                                                        </div>
                                                        <div className="col">
                                                            <input type="radio" className="btn-check" name="timelineFunnelAlternative" id="timelineFunnelAlternative" autoComplete="off" onChange={() => setSelectedTimelineOption('Preferred alternative')} checked={selectedTimelineOption === 'Preferred alternative'} />
                                                            <label className="btn" htmlFor="timelineFunnelAlternative" title="And if you had to change providers for your agribusiness borrowing, which provider is the most appealing to you?">Preferred alternative</label>
                                                        </div>
                                                    </div>
                                                    : selectedTimeLineFilter === 'Attributes' ?
                                                        <div className="bankRadio tilelineFilter mb-sm-3 mb-md-0">
                                                            <div className="col">
                                                                <input type="radio" className="btn-check" name="timelineAttributesAgriBankingSpecialists" id="timelineAttributesAgriBankingSpecialists" autoComplete="off" onChange={() => setSelectedTimelineOption('Are Agri Banking specialists')} checked={selectedTimelineOption === 'Are Agri Banking specialists'} />
                                                                <label className="btn" htmlFor="timelineAttributesAgriBankingSpecialists" title={`Do you associate the following with this bank - 'Are Agri Banking specialists.'`}>Are Agri Banking specialists</label>
                                                            </div>
                                                            <div className="col">
                                                                <input type="radio" className="btn-check" name="timelineAttributesInvestIntoNZ" id="timelineAttributesInvestIntoNZ" autoComplete="off" onChange={() => setSelectedTimelineOption('Invests back into NZ')} checked={selectedTimelineOption === 'Invests back into NZ'} />
                                                                <label className="btn" htmlFor="timelineAttributesInvestIntoNZ" title={`Do you associate the following with this bank - 'Invests back into NZ.'`}>Invests back into NZ</label>
                                                            </div>
                                                            <div className="col">
                                                                <input type="radio" className="btn-check" name="timelineAttributesSupportRuralCommunities" id="timelineAttributesSupportRuralCommunities" autoComplete="off" onChange={() => setSelectedTimelineOption('Supports rural communities')} checked={selectedTimelineOption === 'Supports rural communities'} />
                                                                <label className="btn" htmlFor="timelineAttributesSupportRuralCommunities" title={`Do you associate the following with this bank - 'Supports rural communities.'`}>Supports rural communities</label>
                                                            </div>
                                                            <div className="col">
                                                                <input type="radio" className="btn-check" name="timelineAttributesBrandTrust" id="timelineAttributesBrandTrust" autoComplete="off" onChange={() => setSelectedTimelineOption('Is a brand I trust')} checked={selectedTimelineOption === 'Is a brand I trust'} />
                                                                <label className="btn" htmlFor="timelineAttributesBrandTrust" title={`Do you associate the following with this bank - 'Is a brand I trust.'`}>Is a brand I trust</label>
                                                            </div>
                                                            <div className="col">
                                                                <input type="radio" className="btn-check" name="timelineAttributesSupportingSustainableFarming" id="timelineAttributesSupportingSustainableFarming" autoComplete="off" onChange={() => setSelectedTimelineOption('Is a leader in supporting sustainable farming')} checked={selectedTimelineOption === 'Is a leader in supporting sustainable farming'} />
                                                                <label className="btn" htmlFor="timelineAttributesSupportingSustainableFarming" title={`Do you associate the following with this bank - 'Is a leader in supporting sustainable farming.'`}>Is a leader in supporting sustainable farming</label>
                                                            </div>
                                                            <div className="col">
                                                                <input type="radio" className="btn-check" name="timelineAttributesRuralCommunities" id="timelineAttributesRuralCommunities" autoComplete="off" onChange={() => setSelectedTimelineOption('Has staff based in rural communities')} checked={selectedTimelineOption === 'Has staff based in rural communities'} />
                                                                <label className="btn" htmlFor="timelineAttributesRuralCommunities" title={`Do you associate the following with this bank - 'Has staff based in rural communities.'`}>Has staff based in rural communities</label>
                                                            </div>
                                                            <div className="col">
                                                                <input type="radio" className="btn-check" name="timelineAttributesAgriLending" id="timelineAttributesAgriLending" autoComplete="off" onChange={() => setSelectedTimelineOption('Is the best bank for Agri lending')} checked={selectedTimelineOption === 'Is the best bank for Agri lending'} />
                                                                <label className="btn" htmlFor="timelineAttributesAgriLending" title={`Do you associate the following with this bank - 'Is the best bank for Agri lending.'`}>Is the best bank for Agri lending</label>
                                                            </div>
                                                            <div className="col">
                                                                <input type="radio" className="btn-check" name="timelineAttributesAgriBusiness" id="timelineAttributesAgriBusiness" autoComplete="off" onChange={() => setSelectedTimelineOption('Committed to agribusiness for the long term')} checked={selectedTimelineOption === 'Committed to agribusiness for the long term'} />
                                                                <label className="btn" htmlFor="timelineAttributesAgriBusiness" title={`Do you associate the following with this bank - 'Committed to agribusiness for the long term.'`}>Committed to agribusiness for the long term</label>
                                                            </div>
                                                            {/* <div className="col">
                                                                            <input type="radio" className="btn-check" name="timelineAttributesNoneOfThese" id="timelineAttributesNoneOfThese" autoComplete="off" onChange={() => setSelectedTimelineOption('None of these')} checked={selectedTimelineOption ==='None of these'} />
                                                                            <label className="btn" htmlFor="timelineAttributesNoneOfThese">None of these</label>
                                                                        </div> */}
                                                        </div>
                                                        : selectedTimeLineFilter === 'NPS' ?
                                                            <div className="bankRadio tilelineFilter mb-sm-3 mb-md-0">
                                                                <div className="col">
                                                                    <input type="radio" className="btn-check" name="timelineNPSnps" id="timelineNPSnps" autoComplete="off" onChange={() => setSelectedTimelineOption('NPS')} checked={selectedTimelineOption === 'NPS'} />
                                                                    <label className="btn npspadding-lr" htmlFor="timelineNPSnps">NPS</label>
                                                                </div>
                                                                <div className="col">
                                                                    <input type="radio" className="btn-check" name="timelineNPSPromoters" id="timelineNPSPromoters" autoComplete="off" onChange={() => setSelectedTimelineOption('Promoters')} checked={selectedTimelineOption === 'Promoters'} />
                                                                    <label className="btn npspadding-lr" htmlFor="timelineNPSPromoters">Promoters</label>
                                                                </div>
                                                                <div className="col">
                                                                    <input type="radio" className="btn-check" name="timelineNPSPassives" id="timelineNPSPassives" autoComplete="off" onChange={() => setSelectedTimelineOption('Passives')} checked={selectedTimelineOption === 'Passives'} />
                                                                    <label className="btn npspadding-lr" htmlFor="timelineNPSPassives">Passives</label>
                                                                </div>
                                                                <div className="col">
                                                                    <input type="radio" className="btn-check" name="timelineNPSDetractors" id="timelineNPSDetractors" autoComplete="off" onChange={() => setSelectedTimelineOption('Detractors')} checked={selectedTimelineOption === 'Detractors'} />
                                                                    <label className="btn npspadding-lr" htmlFor="timelineNPSDetractors">Detractors</label>
                                                                </div>
                                                            </div>
                                                            : ''}
                                            </div>
                                            <div className="col-md-9 col-12 mb-md-3 mb-0">
                                                <TimelineChart chartData={data} selectedTimelineOption={selectedTimeLineFilter ? selectedTimelineOption : ''} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}