import { useEffect, useState } from "react";

import Header from "../Header/Header";
import Loader from "../Loader/Loader";

import axios from "axios";
import Swal from 'sweetalert2';

import Nav from "./Filters/Nav";
import MonthYearFilters from "./Filters/MonthYearFilters";
import AttributesFilter from "./Filters/AttributesFilter";
import BrandMetricsChart from "./Charts/BrandMetricsChart";
import CompetitorFilters from "./Filters/CompetitorFilters";
import { useNavigate } from "react-router-dom";


export default function Report() {
    const authUser = localStorage.getItem('ag-authUser');
    const user = JSON.parse(authUser);
    const [loading, setLoading] = useState(false);

    // const months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    // const currentMonth = months[new Date().getMonth()];
    // const currentYear = String(new Date().getFullYear());

    const [month, setMonth] = useState('1');
    // const [year, setYear] = useState('');
    // const [monthYearOptions, setMonthYearOptions] = useState([]);
    // const [selectedMonthYear, setSelectedMonthYear] = useState(null);

    // const [selectedAttribute, setSelectedAttribute] = useState('');
    const [selectedAttributes, setSelectedAttributes] = useState({
        "all": false,
        "Northland": false,
        "Southland": false,
        "Dairy": false,
        "SheepAnBeef": false,
        "upto250ha": false,
        "over250ha": false,
        "under45": false,
        "over45": false
      });
    const [selectedCompetitor, setSelectedCompetitor] = useState('');

    const [data, setData] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (user.role === 'SuperAdmin') {
            navigate('/');
        }
        if (month && selectedCompetitor) {
            getReport(month, selectedAttributes, selectedCompetitor);
        }
    }, [month, selectedAttributes, selectedCompetitor])

    // useEffect(() => {
    //     if (monthYearOptions.length > 0) {
    //         const latestMonthYear = monthYearOptions[0];
    //         setSelectedMonthYear(latestMonthYear);
    //         setMonth(latestMonthYear.month);
    //         setYear(latestMonthYear.year);
    //     }
    // }, [monthYearOptions]);

    const getReport = async (month, attribute, competitor) => {
        let condition = `?self=${user.brandId}`;

        month && (condition += `&month=${month}`);
        // year && (condition += `&year=${year}`);
        attribute && (condition += `&attribute=${JSON.stringify(attribute)}`);
        competitor && (condition += `&competitor=${competitor}`);
        try {
            setLoading(true);
            const response = await axios.get(`/api/brandMatricReport${condition}`);
            setLoading(false);
            let result = response.data;
            if (result.success) {
                setData(result.data);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            Swal.fire("Error in getting prameters List: " + err);
        }
    };

    // const handleMonthChange = (newValue) => {
    //     console.log("Month====",newValue)
    //     if (newValue) {
    //         setMonth(newValue);
    //     } else {
    //         setMonth(null);
    //     }
    // };

    // const handleYearChange = (newValue) => {
    //     console.log("Year====",newValue)
    //     if (newValue) {
    //         setYear(newValue);
    //     } else {
    //         setYear(null);
    //     }
    // }

    // const handleMonthAndYearChange1 = (event, newValue) => {
    //     if (newValue) {
    //         setSelectedMonthYear(newValue);
    //         setMonth(newValue.month);
    //         setYear(newValue.year);
    //     }
    //     else {
    //         setSelectedMonthYear(null);
    //         setMonth(null);
    //         setYear(null);
    //     }
    // };

    const handleMonthAndYearChange = (selectedValue) => {
        console.log("selectedValue-----------------", selectedValue);
        if (selectedValue) {
            getReport(selectedValue);
        }
    };
    
    // const handleAttributeChange = (event) => {
    //     console.log("Attribute====", event.target.value)
    //     setSelectedAttribute(event.target.value);
    // };

    const handleCompetitorChange = (event) => {
        console.log("Competitor====", event.target.value)
        setSelectedCompetitor(event.target.value);
    };

    return (
        <>
            <Loader loading={loading} />
            <Header />
            <div className="ssDiv" style={{ backgroundColor: '#dae8d8' }}>
                <section className="main-content ss-content">
                    <div className="container">
                        {/* <Nav /> */}
                        <div className="card bg-CardColor">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <div className="d-flex align-items-center justify-content-between tabHeading">
                                            <h3><span className="main_bank-span">{user?.brand?.name || ''}</span> Brand Performance</h3>
                                            <div className="selectLable d-none d-md-block">
                                                <MonthYearFilters
                                                    // brandId={user.brandId}
                                                    handleMonthAndYearChange={handleMonthAndYearChange} 
                                                    // monthYearOptions={monthYearOptions}
                                                    // setMonthYearOptions={setMonthYearOptions}
                                                    // selectedMonthYear={selectedMonthYear}
                                                    month={month}
                                                    setMonth={setMonth}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="row border-bottom border-dark pb-md-0 pb-2">
                                            <div className="col-md-10 col-8">
                                                <div className="tabHeading">
                                                    <h3><span>Rabobank</span> Brand Performance</h3>
                                                </div>
                                            </div>
                                            <div className="col-md-2 selectLable d-none d-md-block">
                                                <MonthYearFilters
                                                    // brandId={user.brandId}
                                                    handleMonthAndYearChange={handleMonthAndYearChange} 
                                                    // monthYearOptions={monthYearOptions}
                                                    // setMonthYearOptions={setMonthYearOptions}
                                                    // selectedMonthYear={selectedMonthYear}
                                                    month={month}
                                                    setMonth={setMonth}
                                                />
                                            </div>

                                        </div> */}
                                    </div>
                                    
                                    <AttributesFilter 
                                        selectedAttributes={selectedAttributes}
                                        setSelectedAttributes={setSelectedAttributes}
                                    />
                                    <div className="col-6 mb-3 selectLable d-md-none d-sm-block">
                                        <MonthYearFilters
                                            // brandId={user.brandId}
                                            handleMonthAndYearChange={handleMonthAndYearChange} 
                                            // monthYearOptions={monthYearOptions}
                                            // setMonthYearOptions={setMonthYearOptions}
                                            // selectedMonthYear={selectedMonthYear}
                                            month={month}
                                            setMonth={setMonth}
                                        />
                                    </div>
                                    <div className="col-md-8 col-12">
                                        <CompetitorFilters selectedCompetitor={selectedCompetitor} setSelectedCompetitor={setSelectedCompetitor} handleCompetitorChange={handleCompetitorChange} setLoading={setLoading} />
                                    </div>
                                    {/* <div className="col-md-12 mb-3">
                                        <div className="filterRadioButton radioBtn">
                                            <div className="col">
                                                <input type="radio" class="btn-check" name="options" id="option-Rabobank" autocomplete="off" checked />
                                                <label class="btn" for="option-Rabobank">Rabobank</label>
                                            </div>
                                            <div className="col">
                                                <input type="radio" class="btn-check" name="options" id="option-ASB" autocomplete="off" />
                                                <label class="btn" for="option-ASB">ASB</label>
                                            </div>
                                            <div className="col">
                                                <input type="radio" class="btn-check" name="options" id="option-ANZ" autocomplete="off" />
                                                <label class="btn" for="option-ANZ">ANZ</label>
                                            </div>
                                            <div className="col">
                                                <input type="radio" class="btn-check" name="options" id="option-BNZ" autocomplete="off" />
                                                <label class="btn" for="option-BNZ">BNZ</label>
                                            </div>
                                            <div className="col">
                                                <input type="radio" class="btn-check" name="options" id="option-Kiwibank" autocomplete="off" />
                                                <label class="btn" for="option-Kiwibank">Kiwibank</label>
                                            </div>
                                            <div className="col">
                                                <input type="radio" class="btn-check" name="options" id="option-TSB" autocomplete="off" />
                                                <label class="btn" for="option-TSB">TSB</label>
                                            </div>
                                            <div className="col">
                                                <input type="radio" class="btn-check" name="options" id="option-Westpac" autocomplete="off" />
                                                <label class="btn" for="option-Westpac">Westpac</label>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-md-8 col-12 mb-md-3 mb-0 p-0">
                                        {data ? <BrandMetricsChart chartdata={data} /> : "Processing...!"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}