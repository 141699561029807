import { Link, useLocation, useNavigate } from "react-router-dom"
import Nav from "../Report/Filters/Nav";

export default function Header({pageTitle, imgSrc,  ...rest}){
    const navigate = useNavigate();
    const location = useLocation();
    const authUser = localStorage.getItem('ag-authUser');
    const user = JSON.parse(authUser);
    const brandId = user.brandId;

    const handleLogout = () => {
        localStorage.clear();
        navigate("/");
    };
    
    return(
        <section className="header fixed-top">
            <nav className="navbar navbar-expand-lg">
                <div className="container">
                    <Link className="navbar-brand d-flex py-0 order-1 order-md-1" >
                        <h1>Agtrack</h1>
                        <span className="tm">TM</span>
                    </Link>
                    {brandId && brandId !== null ? 
                    <div className="order-4 order-md-2">
                        <Nav />
                    </div>
                    : ""}
                    <div className="dropdown d-md-none d-sm-block ms-auto me-2 order-2">
                        <div className="d-flex align-items-center dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <div className="user-details">
                                <img src={user && user.image ? user.image : "assets/img/images.jpg"} className="" />
                            </div>
                        </div>
                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow">
                            <li>
                                <div className="dropdown-divider"></div>
                            </li>
                            <li>
                                <div className="user-name">
                                    <p>{user && user.firstName ? user.firstName: ''}</p>
                                    <span>{user && user.role ? user.role: ''}</span>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-divider"></div>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/profile">
                                    <i className="fa-solid fa-gear me-2"></i>
                                    <span className="">Setting</span>
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/login" onClick={handleLogout}>
                                    <i className="fa-solid fa-power-off me-2"></i>
                                    <span className="">Logout</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <button className="navbar-toggler ms-md-auto me-sm-0 me-2 border-0 shadow-none order-3" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span className="text-dark fs25"><i className="fa-solid fa-bars"></i></span>
                    </button>
                    <div className="collapse navbar-collapse ss_nav order-3 order-md-3" id="navbarSupportedContent">
                        {/* <ul className="navbar-nav ss-nav mx-auto mb-2 mb-lg-0"> */}
                        <ul className="navbar-nav ss-nav ms-auto mb-2 mb-lg-0">
                            {user && user.role && user.role === 'SuperAdmin' ? 
                            <>
                                <li className="nav-item">
                                    <Link className={`nav-link hover-line ${location.pathname === '/brands'?'active':''}`} aria-current="page" to="/brands">Brands</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link hover-line ${location.pathname === '/data'?'active':''}`} to="/data">Data</Link>
                                </li>
                            </>
                            : ""}
                            {brandId && brandId !== null ? 
                                <>
                                    <li className="nav-item">
                                        <Link className={`nav-link hover-line ${(location.pathname === '/performance' || location.pathname === '/brandAttributes' || location.pathname === '/nps' || location.pathname === '/timeline') ?'active':''}`} to="/performance">Report</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className={`nav-link hover-line ${location.pathname === '/users'?'active':''}`} to="/users">Users</Link>
                                    </li>
                                </>
                            : ""}
                        </ul>
                    </div>
                    <div className="dropdown d-none d-md-block order-md-4">
                        <div className="d-flex align-items-center dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <div className="user-details">
                                <img src={user && user.image ? user.image : "assets/img/images.jpg"} className="" />
                            </div>
                        </div>
                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow">
                            <li>
                                <div className="dropdown-divider"></div>
                            </li>
                            <li>
                                <div className="user-name">
                                    <p>{user && user.firstName ? user.firstName: ''} {user && user.lastName ? user.lastName: ''}</p>
                                    <span>{user && user.role ? user.role: ''}</span>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-divider"></div>
                            </li>
                            <li>
                                <Link className="dropdown-item userDropdown" to="/profile">
                                    <i className="fa-solid fa-gear me-2"></i>
                                    <span className="">Setting</span>
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item userDropdown" to="/login" onClick={handleLogout}>
                                    <i className="fa-solid fa-power-off me-2"></i>
                                    <span className="">Logout</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </section>
    )
}