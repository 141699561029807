import { Routes, Route } from 'react-router-dom';
import PublicRoutes from './Components/Routes/PublicRoutes';
import ProtectedRoutes from './Components/Routes/ProtectedRoutes';
import "./intercepter";
import Login from './Components/Login/Login';
import ForgotPassword from './Components/ForgotPassword/ForgotPassword';
import Users from './Components/Users/List';
import Brands from './Components/Brands/List';
import Data from './Components/Data/List';
import ChangePassword from './Components/AccountSettings/ChangePassword';
import Profile from './Components/AccountSettings/Profile';
import PageNotFound from './Components/PublicPages/PageNotFound';

import BrandMetrics from './Components/Report/BrandMetricsReport';
import BrandAttributes from './Components/Report/BrandAttributesReport';
import NPS from './Components/Report/NPSReport';
import Timeline from './Components/Report/TimelineReport';
import { useEffect } from 'react';

function App() {
  const events = ["load","mousemove","mousedown","click","scroll","keypress"];
  let timer;
  
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logoutAction();
    }, 1800000); // 60000 = 1min
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  const logoutAction = () => {
    localStorage.clear();
    // window.location.pathname = "/login"; // local
    // window.location.pathname = "/ag_track/login"; //development
    window.location.pathname = "/"; //live
  };

  return (
    // <Router>
    <Routes>
      <Route path="/" element={<PublicRoutes />}>
        <Route element={<Login />} path="/" />
        <Route element={<Login />} path="/login" />
        <Route element={<ForgotPassword />} path="/forgot-password" />
      </Route>

      <Route path="/" element={<ProtectedRoutes />}>
        <Route element={<Users />} path="/users" />
        <Route element={<Profile />} path="/profile" />
        <Route element={<ChangePassword />} path="/change-password" />
        <Route element={<Brands />} path="/brands" />
        <Route element={<Data />} path="/data" />

        <Route element={<BrandMetrics />} path="/performance" />
        <Route element={<BrandAttributes />} path="/brandAttributes" />
        <Route element={<NPS />} path="/nps" />
        <Route element={<Timeline />} path="/timeline" />
      </Route>

      <Route path="/404" element={<PageNotFound />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
    // </Router>
  );
}

export default App;