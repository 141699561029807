import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function CompetitorFilters({selectedCompetitor, setSelectedCompetitor, handleCompetitorChange, setLoading, month, year, ...rest}) {

    const authUser = localStorage.getItem('ag-authUser');
    const user = JSON.parse(authUser);
    const [competitors, setCompetitors] = useState([]);

    useEffect(() => {
        if(!user.brandId) {
            Navigate('/');         
        }
        getCompetitorsList();
    },[])

    useEffect(() => {
        if (!selectedCompetitor && competitors.length > 0) {
            // Find the first competitor with type 'Competitor'
            const firstCompetitor = competitors.find(competitor => competitor.type === 'Competitor');
            if (firstCompetitor) {
                setSelectedCompetitor(firstCompetitor.id);
            }
        }
    }, [competitors, selectedCompetitor]);

    const getCompetitorsList = async () => {
        const brandId = user.brandId;
        let url = `/api/competitors?brandId=${brandId}`;
        if( month && year ){
            url = `/api/competitorsForBrandAttributes?brandId=${brandId}&month=${month}&year=${year}`;
        }
        try {
            // setLoading(true);
            const response = await axios.get(url);
            setLoading(false);
            let result = response.data;
            if (result.success) {
                console.log("result.data",result.data)
                setCompetitors(result.data);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            Swal.fire("Error in getting prameters List: " + err);
        }
    };

    return(
        <div className=" mb-15 filterRadioButton bankRadio">
            {competitors.map((competitor) => 
                (
                    <Fragment key={`competitors${competitor.type}_${competitor.id}`}>
                    {competitor.type === 'Self'?
                        <div className="col">
                            <input type="radio" className="btn-check" name="self" id={`self_competitor${competitor.id}`} autoComplete="off" 
                            defaultChecked value={competitor.id}/>
                            <label className="btn fw-semibold" htmlFor={`self_competitor${competitor.id}`} style={{backgroundColor:'#f06b22'}}>{competitor.name}</label>
                        </div>
                        :""
                    }
                    {competitor.type === 'Competitor' && competitor.name !== 'None of these' ?
                        <div className="col lightButton">
                            <input type="radio" className="btn-check" name="competitor" id={`competitor${competitor.id}`} autoComplete="off" 
                            onChange={handleCompetitorChange} checked={parseInt(selectedCompetitor) === parseInt(competitor.id)} value={competitor.id}/>
                            <label className="btn" htmlFor={`competitor${competitor.id}`}  style={{backgroundColor: (parseInt(selectedCompetitor) === parseInt(competitor.id)) ? '#dae8d8' : ''}} >{competitor.name}</label>
                        </div>
                        :""
                    }
                    </Fragment>
                )
            )}
        </div>
    )
}