import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from "react-chartjs-2";

// Register required components for Doughnut chart
ChartJS.register(ArcElement, Tooltip, Legend);

export default function NPSChart({ chartData, backgroundColor, color }) {
    const isSelfDataValid = chartData && chartData.length > 0;
    if (!isSelfDataValid) {
        return <div className="App" style={{ height: 'auto', width: "100%", color: '#000' }}>
            No data available!
        </div>;
    }

    

    const customPlugin = {
        id: 'customText',
        beforeDraw: (chart) => {
            const { ctx, chartArea } = chart;
            const { width, height } = chartArea;
            const centerX = width / 2;
            const centerY = height / 2;
            const fontSize = 80; //100;
            const npsValue = chartData ? parseFloat(chartData[0].nps).toFixed() : 0;

            ctx.save();
            ctx.font = `${fontSize}px VisbyCF-medium`;
            ctx.fillStyle = '#000000';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            // ctx.fillText((chartData ? parseFloat(chartData[0].nps).toFixed() : 0), centerX, centerY); // Display a custom value inside the chart
            const displayValue = npsValue >= 0 ? `+${npsValue}` : npsValue;
            ctx.fillText(displayValue, centerX, centerY); // Display a custom value inside the chart
            ctx.restore();
        }
    };

    const data = {
        labels: ['Promoters', 'Passives', 'Detractors'],
        datasets: [{
            label: 'Values',
            data: chartData ? [chartData[0].promoters, chartData[0].passive, chartData[0].detractors] : [],
            backgroundColor: backgroundColor,
            borderColor: '#fff',
            borderWidth: 0
        }]
    };

    const options = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return tooltipItem.label + ': ' + tooltipItem.raw;
                    }
                },
                titleFont: {
                    family: 'VisbyCF-medium, sans-serif',
                    letterSpacing: 1.5,
                    size: 12,
                },
                bodyFont: {
                    family: 'VisbyCF-medium, sans-serif',
                    letterSpacing: 1.5,
                    size: 11
                },
                footerFont: {
                    family: 'VisbyCF-medium, sans-serif',
                    letterSpacing: 1.5,
                    size: 10
                }
            },
            legend: {
                display: false,
                position: 'bottom', // Set legend to display below the chart
                align: 'center',
                labels: {
                    color: '#000',
                    boxWidth: 20,
                    padding: 10,
                    font: {
                        size: 12,
                        family: 'VisbyCF-Medium, sans-serif',
                        letterSpacing: 1.5,
                    },
                    generateLabels: (chart) => {
                        const { datasets } = chart.data;
                        return chart.data.labels.map((label, index) => {
                            const value = datasets[0].data[index]; // Access the corresponding value
                            console.log("valuevalue",value)
                            // If value is undefined, set it to 0
                            const displayValue = value !== undefined ? value : 0; 
    
                            return {
                                text: `${label}: ${displayValue}`, // Display label and value
                                fillStyle: datasets[0].backgroundColor[index],
                                strokeStyle: '#fff',
                                lineWidth: 3,
                                hidden: false,
                                index,
                            };
                        });
                    },
                }
            },
            datalabels: {
                display: false
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        cutout: '87%', // Adjust size of the hole (87% of the chart's radius)
    };

    return (
        // <div className="App" style={{ height: 'auto', width: "100%", textAlign: 'center' }}>
        //     <div style={{ height: '300px' }}>
        //         <Doughnut key={chartData ? chartData[0].nps : 'no-data'} data={data} options={options} plugins={[customPlugin]} />
        //     </div>
        //     {/* Add the bank name below the chart */}
        //     <div style={{ marginTop: '10px', color: color, fontSize: '16px', fontWeight: 'bold' }}>
        //         {chartData ? (chartData[0].main_bank) : ""}
        //     </div>
        // </div>

        <div className="App nps-Chart" style={{ height: 'auto', width: "100%", textAlign: 'center' }}>
            <div style={{ height: '200px' }}>
                <Doughnut key={chartData ? chartData[0].nps : 'no-data'} data={data} options={options} plugins={[customPlugin]} />
            </div>
            {/* Add the bank name below the chart */}
            <div style={{ marginTop: '10px', color: color, fontSize: '16px', fontFamily: 'VisbyCF-Bold, sans-serif', letterSpacing: 1}}>
                {chartData ? (chartData[0].main_bank) : ""}
            </div>
            {/* Custom legend section */}
            <div className="custom-legend" style={{ marginTop: '10px', color: 'black', width: '100%', }}>
                {data.labels.map((label, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '4px', gap: '10px' }}>
                        <div style={{width: '100px'}}>
                            <span style={{ float: 'right',fontSize: '14px', fontFamily: 'VisbyCF-medium, sans-serif', letterSpacing: 1}}>{`${data.datasets[0].data[index]+'%' || 0+'%'}`}</span>
                        </div>
                        <span style={{
                            width: '20px',
                            height: '14px',
                            backgroundColor: data.datasets[0].backgroundColor[index],
                        }} />
                        <div className="text-start" style={{width: '100px'}}>
                            <span style={{fontSize: '14px', fontFamily: 'VisbyCF-medium, sans-serif', 
                                // letterSpacing: 0.4
                            }}>
                                {`${label}`}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}